let vAPI_BASE;
let vFILE_URL;

if (process.env.REACT_APP_BUILD_ENV == "PRODUCTION") {
    // Production
    vAPI_BASE = 'https://api.instacoupon.com/merchant/';
    vFILE_URL = 'https://api.instacoupon.com/';   
} else if (process.env.REACT_APP_BUILD_ENV == "STAGING") {
    // Staging
    vAPI_BASE = 'https://staging-api.instacoupon.com/merchant/';
    vFILE_URL = 'https://staging-api.instacoupon.com/';
} else {
    vAPI_BASE = 'http://localhost:3000/merchant/';
    vFILE_URL = 'https://staging-api.instacoupon.com/';
}

export const BASE_URL = vAPI_BASE;
export const MEDIA_BASE_URL = vFILE_URL;

export const COUNTRY_CODE = "+1"

export const MOBILE_REGEX = /^[0-9]{10}$/;

export const PASSWORD_COMPLEX_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%^&~"^+-/=,.?(){}[|\]<>:'_;*?])[A-Za-z\d#$@!%^&~"^+-/=,.?(){}[|\]<>:'_;*?]{8,}$/g;

export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[\W_])(?!.*\s).{8,}$/

export const EMAIL_REGEX = /^[^<>()[\]\\,;:~`£{}'"\%#^\s@\$&!@]+(?!-)[a-zA-Z0-9]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NOSPACE_REGEX = /^(?!\s+$).*/;

export const NOSPACE_REGEX_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/


export const NOSPACE = "Spaces are not allowed";

export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/gif", "video/mp4", "video/x-ms-wmv", "video/quicktime", "video/webm", "application/pdf", "text/plain", "application/msword", "application/vnd.ms-excel", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];

export const MULTIPLESPACE = /  +/g;

export const CARD_REGEX = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g;

export const NODIGITREGEX = /^([^0-9]*)$/

export const DECIMALPATTERN = /^[0-9]+(\.[0-9]{1,2})?$/;

export const URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
export const LINK_REGEX = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

export const GENDER_OPTIONS = [
    {value:"Male", label:"Male"},
    {value:"Female", label:"Female"},
    {value:"Prefer not to say", label:"Prefer not to say"},
]