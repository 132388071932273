import { Avatar } from "@mui/material";
import Select from "react-select";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {tostE, tostS } from "../../../Utils/Toast";
import logo from '../../../Assets/images/logo.png'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { getBusinessDetails,  updateBusinessDetails} from "../../../Services/Businessconfig";
import * as Yup from "yup";
import { getCategories } from "../../../Services/AuthServices";
import { MEDIA_BASE_URL} from "../../../Utils/Constants";
import GoogleAutocompleteComp from "../../../Components/MyCoupons/GoogleAutocompleteComp";
import statesData from "../../../Components/Common/AllStates";
import moment from 'moment-timezone';
// import imageCompression from 'browser-image-compression';
import { checkAddressValidity, getStateCode } from "../../../Utils/CommonFunctions";
import imageCompression from 'image-compressor.js';

const validationSchema = Yup.object().shape({
  businessImage: Yup.mixed().required("Business image is required"),
  address: Yup.string()
    .required("Address is required")
    .matches(
      /^[^\s]+(?:\s.*)?$/,
      "Address should not start with a blank space"
    ),
  city: Yup.string()
    .required("City is required")
    .matches(/^[^\s]+(?:\s.*)?$/, "City should not start with a blank space"),
  state: Yup.string()
    .required("State is required")
    .matches(/^[^\s]+(?:\s.*)?$/, "State should not start with a blank space"),
  address2: Yup.string()
    .max(150, "Address line 2  should be less than 150 characters")
    .matches(/^[^\s]+(?:\s.*)?$/, "Address line 2 should not start with a blank space"),
  zipCode: Yup.string()
    .required('Zip code is required')
    .matches(/^[a-zA-Z0-9]+$/, 'Zip code must contain only numbers and letters')
    .min(4, 'Zip code must be at least 4 characters')
    .max(10, 'Zip code must not exceed 10 characters'),
  businessHourStartTime: Yup.string().required("Start time is required"),
  businessHourEndTime: Yup.string()
    .required("End time is required")
    .test('is-before-max-time', 'End time must be before 11:30 PM', function(value) {
      if (!value) return true;
      const selectedTime = dayjs(value, 'HH:mm');
      const maxTime = dayjs().hour(23).minute(31);
      return selectedTime.isBefore(maxTime);
    })
    .when(["businessHourStartTime"], (startTime, schema) => {
      return schema.test({
        test: function (endTime) {
          if (!endTime || !startTime) return true;

          const [startHour, startMinute] = String(startTime)
            .split(":")
            .map(Number);
          const [endHour, endMinute] = String(endTime).split(":").map(Number);
          if (endHour > startHour) return true;
          if (endHour === startHour && endMinute > startMinute) return true;

          return false;
        },
        message: "Ensure that the start time is before the end time.",
      });
    }),
  categoryIds: Yup.array().min(1, "Service category is required"),
  businessContactNumber: Yup.string()
    .required("Business contact number is required")
    .matches(/^\d{10}$/, "Invalid phone number format")
    .max(150, "Business contact number should be less than 150 characters")
    .matches(
      /[^\s*].*[^\s*]/g,
      "Business contact number should not start with a blank space"
    ),
  taxNumber: Yup.string()
    .required("EIN / Tax ID Number is required")
    .matches(/[^\s*].*[^\s*]/g, "EIN / Tax ID Number should not start with a blank space")
    .matches(/^\d{2}-\d{7}$/, 'Invalid EIN / Tax ID Number format')
    ,    
  leadTime: Yup.number()
    .required("Lead time is required")
    .positive("Lead time must be a positive number"),
  policy: Yup.string()
    .required("Policy is required")
    .matches(/[^\s*].*[^\s*]/g, "Policy should not start with a blank space")
    .min(10, "Policy should be of at least  10 characters"),
  bankAccountNumber: Yup.string()
    .required("Bank account number is required")
    .max(150, "Bank account number should be less than 150 characters")
    .matches(
      /^[^\s]+(?:\s.*)?$/,
      "Bank account number should not start with a blank space"
    ),

  confirmbankAccountNumber: Yup.string()
  .required("Bank account number is required")
  .max(150, "Bank account number should be less than 150 characters")
  .matches(
    /^[^\s]+(?:\s.*)?$/,
    "Bank account number should not start with a blank space"
  )
  .test('bankAccountNumber-match', 'Confirm Bank account number must match', function(value){
    return this.parent.bankAccountNumber === value
  }),  
  routingNumber: Yup.string()
    .required("Bank routing number is required")
    .max(150, "Bank routing number should be less than 150 characters")
    .matches(
      /^[^\s]+(?:\s.*)?$/,
      "Bank routing number should not start with a blank space"
    ),
});

export default function UpdateBusinessConfig() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [startTime, setStartTime] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [routingnumber, setRoutingnumber] = useState('');
  const [states, setStates] = useState(statesData);
  const [accountnumber , setAccountNumber] = useState('');
  const [taxnumber, setTaxnumber] = useState(''); 
    
  useEffect(() => {
    getCategories()
      .then((res) => {
        if (res.status === 200) {
          const resData = res.data.data;
          const modifiedData = resData.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setCategoryList(modifiedData);
        }
      })
      .catch((err) => {
      });
  }, []);

  useEffect(() => {
    setStartTime(new Date()); 
    setLoading(true);
    async function fetchData() {
      try {
        const response = await getBusinessDetails();
        setData(response.data.data);
        const accountNumber= response?.data?.data?.bankAccountNumber;
        const routingNumber = response?.data?.data?.routingNumber;
        const maskedRoutingNumber = routingNumber && routingNumber.length > 4 ? 'XXXXXXXX-' + routingNumber.substr(-4) : routingNumber;
        const maskedAccountNumber = accountNumber && accountNumber.length > 4 ? 'XXXXXXXX-' + accountNumber.substr(-4) : accountNumber;
        setRoutingnumber(maskedRoutingNumber);
        setAccountNumber(maskedAccountNumber);

        const taxtnum = response?.data?.data?.taxNumber;
        if (typeof taxtnum === 'string') {
          const taxNumberString = taxtnum.toString();
          if (taxNumberString.length === 9) {
              const formattedTaxNumber = `${taxNumberString.slice(0, 2)}-${taxNumberString.slice(2, 9)}`;
              setTaxnumber(formattedTaxNumber);
          } else {
              setTaxnumber('');
          }
      } else {
          setTaxnumber('');
      }
      } catch (error) {
        tostE("Error fetching business details:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
          setStartTime(null);
        }, 1000);
      }
    }
    handleAddressCityChange();
    fetchData();
  }, []);

  function getFileExtension(fileName) {
    return fileName.slice(
      (Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1
    );
  }
  const formik = useFormik({
    initialValues: {
      imagePath: "merchant",
      businessImage: data?.businessImage || "",
      address: data?.address || "",
      city: data?.city || "",
      state: data?.state || "",
      zipCode: data?.zipCode || "",
      businessHourStartTime: data?.businessHourStartTime || '08:00:00',
      businessHourEndTime: data?.businessHourEndTime || '20:00:00',
      categoryIds: data?.categoryIds?.map((item) => ({ value: item?.id, label: item?.name })) || [],
      businessContactNumber: data?.businessContactNumber || "",
      leadTime: data?.leadTime || "5",
      policy: data?.policy || "",
      notificationText: data?.notificationText || false,
      notificationEmail: data?.notificationEmail || false,
      bankAccountNumber: data?.bankAccountNumber ? accountnumber : "",
      routingNumber: data?.routingNumber ? routingnumber : '',
      lat: data?.lat || "",
      lng: data?.lng || "",
      address2:data?.address2 || "",
      confirmbankAccountNumber:data?.bankAccountNumber ? accountnumber : "",
      taxNumber:taxnumber||"",
      timeZone:data.timeZone||"",
      formattedAddress:data.formattedAddress||"",
      bankAccountType:data.bankAccountType||'Checking',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {  
      const addressValid = await checkAddressValidity(values);
      if (!addressValid) {
          return;
      } else {
        
          const { formattedAddress ,address, city, state, zipCode , lat,lng } = addressValid;
            values.lat = lat;
            values.lng = lng;
          }

        values.taxNumber = values.taxNumber.replace(/-/g, '');

       
        let formData = new FormData();
        Object.keys(values).forEach((key) => {
          
          if (key === 'confirmbankAccountNumber') {
            return;
          }

          if ((key === 'bankAccountNumber') && values[key].includes('XXXX')) {
              values[key]=data?.bankAccountNumber;
          }
          if ((key === 'routingNumber') && values[key].includes('XXXX')) {              
              values[key]=data?.routingNumber;
          }
            
          if (key == "categoryIds") {
            formData.append(
              key,
              JSON.stringify(values[key].map((item) => item?.value))
            );
            return
          }
          if (values[key] instanceof File) {
            let ext = getFileExtension(values[key].name);
            formData.append(key, values[key], `${key}.${ext}`);
            return;
          }
          formData.append(key, values[key]);
        });

        setStartTime(new Date()); 
        setLoading(true);    

        const response = await updateBusinessDetails(formData);
        if(response.status===200){
          setTimeout(() => {
            setLoading(false);
            setStartTime(null);
            navigate("/merchant/business-config/detail");
            tostS(response?.data?.message);
          }, 500);
        }
        
        
      } catch (error) {
        setLoading(false);
        setStartTime(null);
        tostE(error?.response?.data?.message)
      } 
    },
  });
  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
    setFieldError,
  } = formik;



  const handleImageCompression = async (file) => {
    try {
        const compressor = new imageCompression();
        const compressedBlob = await compressor.compress(file, {
            maxWidth: 300,
            maxHeight: 300,
            quality: 0.8,
            mimeType: 'image/jpeg',
        });

        const compressedFile = new File([compressedBlob], file.name, {
            type: compressedBlob.type,
            lastModified: Date.now(),
        });

        return compressedFile;
    } catch (error) {
        throw error;
    }
};

const handleImageChange = async (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!validImageTypes.includes(file.type)) {
        tostE("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
        return;
      }
  
      const img = new Image();
      img.onload =async function () {
      
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true
        };
        try {
          setLoading(true)
          const compressedFile = await handleImageCompression(file);
          setLoading(false)
          setFieldValue('businessImage', compressedFile);
        } catch (error) {
          tostE("Error while compressing the image. Please try again.");
        }
      };
      img.onerror = function () {
        tostE("The uploaded image is corrupted. Please upload a valid image.");
      };
      img.src = URL.createObjectURL(file);
    }
  };
  
  const renderAvatar = () => {
    if (values.businessImage instanceof File || values.businessImage instanceof Blob) {
      return <Avatar className="avatar-preview" src={URL.createObjectURL(values.businessImage)} />;
    }
    if (typeof values.businessImage === 'string' && values.businessImage) {
      return <Avatar className="avatar-preview" src={MEDIA_BASE_URL + values.businessImage} />;
    }
    return <Avatar className="avatar-preview" src="default-avatar.jpg" />;
  };
  
  const handleStartTimeChange = (time) => {
    if (!time) return; 
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let finalTime =  dayjs(time, "HH:mm:ss") 
    const formattedStartTime = moment(new Date(finalTime) , "HH:mm").tz(values.timeZone?values.timeZone : userTimeZone).format("HH:mm:ss");
    setFieldValue("businessHourStartTime", formattedStartTime);
    
  };
  
  const handleEndTimeChange = (time) => {
    if (!time) return; 
    let finalTime =  dayjs(time, "HH:mm:ss")  
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const selectedTime = moment(new Date(finalTime), "HH:mm").tz(values.timeZone?values.timeZone : userTimeZone);
    const maxTime = moment().tz(values.timeZone?values.timeZone : userTimeZone).hour(23).minute(30); 
    if (selectedTime.isAfter(maxTime)) {
      return; 
    }
    const formattedEndTime = selectedTime.format("HH:mm:ss");
    setFieldValue("businessHourEndTime", formattedEndTime);
  };
  
  const handleAddressCityChange = () => {
    handleStartTimeChange(data?.businessHourStartTime);
    handleEndTimeChange(data?.businessHourEndTime);
  };
  
  useEffect(() => {
    handleAddressCityChange();
  }, [values.city , values.state]);


  useEffect(() => {
    let fullAddress= `${values.address}, ${values.city}, ${getStateCode(values.state)} ${values.zipCode} ${'US'}`
    setFieldValue("formattedAddress", fullAddress);
  }, [values.city , values.state , values.address]);

  return (
    <div className="add-new-coupon-sec section-space">
      {loading && (
        <div className='preload'>
          <img src={logo} alt="Insta-coupon Logo" className='preloadlogo' />
        </div>
      )}
      <div className="container">
        <div className="coupan-sec-top-head mb-4">
          <div className="page-title ">
            <h1>Edit Business Configuration</h1>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/merchant/business-config/detail">
                  Business Configuration
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit Business Configuration
              </li>
            </ol>
          </nav>
        </div>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div className="add-new-coupon-info-main mt-5">
              <div className="row">
                <div className="col-md-8 add-new-coupon-box m-auto">
                  <div className="box-shadow ">
                  <div className="upload-photo m-auto">
                      <div className="avatar-upload">
                        <div className="avatar-edit">
                          <input
                            type="file"
                            id="imageUpload"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => { handleImageChange(e, setFieldValue) }}
                          />
                          <label htmlFor="imageUpload" className="img-upload-icon">
                            <i className="ri-add-fill"></i>
                          </label>
                        </div>
                        {renderAvatar()}
                      </div>
                      <div className="text-center">
                        {errors.businessImage && touched.businessImage ? (
                          <span className="form-error">
                            {errors.businessImage}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <h4 className="sub-title-page mb-3 mt-3">Business Details</h4>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Address</label>
                        <GoogleAutocompleteComp formik={formik} />
                        {errors.address && touched.address ? (
                          <span className="form-error">{errors.address}</span>
                        ) : null}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="form-label">Address Line 2(Suite, Floor, Landmark)</label>
                        <input
                          type="text"
                          name="address2"
                          placeholder="Enter Suite, Floor, Landmark"
                          className={"form-control" +(errors.address2 && touched.address2 ? " is-invalid" : "")}
                          value={values.address2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="address2"
                        />
                        {errors.address2 && touched.address2 ? (
                          <span className="form-error">{errors.address2}</span>
                        ) : null}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="form-label">City</label>
                        <input
                          type="text"
                          name="city"
                          placeholder="Enter city"
                          className={
                            "form-control" +
                            (errors.city && touched.city ? " is-invalid" : "")
                          }
                          value={values.city}
                          onChange={(e) => {
                            setFieldValue("city",e?.target?.value)
                            handleAddressCityChange(); 
                          }}
                          onBlur={handleBlur}
                          title="City"
                        />
                        {errors.city && touched.city ? (
                          <span className="form-error">{errors.city}</span>
                        ) : null}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">State</label>
                        <select
                          name="state"
                          className={
                            "form-control" +
                            (errors.state && touched.state ? " is-invalid" : "")
                          }
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {states?.map((state,index) => (
                              <option key={index} value={state.value}>
                                  {state.label}
                              </option>
                          ))}
                        </select>
                        {errors.state && touched.state ? (
                          <span className="form-error">{errors.state}</span>
                        ) : null}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Zip Code</label>
                        <input
                          type="text"
                          name="zipCode"
                          placeholder="Enter zip code"
                          className={
                            "form-control" +
                            (errors.zipCode && touched.zipCode
                              ? " is-invalid"
                              : "")
                          }
                          value={values.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Zip Code"
                        />
                        {errors.zipCode && touched.zipCode ? (
                          <span className="form-error">{errors.zipCode}</span>
                        ) : null}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Business Hours</label>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['TimePicker']} >
                                <TimePicker
                                  value={dayjs(values?.businessHourStartTime, "HH:mm:ss")}
                                  className='mobilepicker-custom timepicker-custom'
                                  onChange={(newValue) =>
                                    handleStartTimeChange(newValue)}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {errors.businessHourStartTime &&
                              touched.businessHourStartTime ? (
                              <span className="form-error">
                                {errors.businessHourStartTime}
                              </span>
                            ) : null}
                          </div>
                          <div className="col-md-6">

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['TimePicker']}>
                                <TimePicker
                                  value={dayjs(values?.businessHourEndTime, "HH:mm:ss")}
                                  className='mobilepicker-custom timepicker-custom'
                                  onChange={(e) =>
                                    handleEndTimeChange(e)}
                                  maxTime={dayjs().hour(23).minute(30)}
                                />
                              </DemoContainer>
                            </LocalizationProvider>

                            {errors?.businessHourEndTime &&
                              touched?.businessHourEndTime ? (
                              <span className="form-error">
                                {errors?.businessHourEndTime}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="form-label">Contact Number</label>
                        <input
                          type="text"
                          name="businessContactNumber"
                          placeholder="Enter contact number"
                          className={
                            "form-control" +
                            (errors.businessContactNumber &&
                              touched.businessContactNumber
                              ? " is-invalid"
                              : "")
                          }
                          value={values.businessContactNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Contact Number"
                        />
                        {errors.businessContactNumber &&
                          touched.businessContactNumber ? (
                          <span className="form-error">
                            {errors.businessContactNumber}
                          </span>
                        ) : null}
                      </div>

                      
                      {/********************************* */}

                      <div className="col-md-6 mb-3">
                        <label className="form-label">EIN / Tax ID Number</label>
                        <input
                            type="text"
                            name="taxNumber"
                            placeholder="EIN / Tax ID Number"
                            className={
                              "form-control" +
                              (errors.taxNumber && touched.taxNumber ? " is-invalid" : "")
                            }
                            value={
                              typeof values.taxNumber === 'string'
                                ? values.taxNumber.replace(/(\d{2})\-?(\d{9})/, "$1-$2")
                                : values.taxNumber
                            }
                            onChange={(e) => {
                              const input = e.target.value.replace(/\D/g, '').substring(0, 9); 
                              const regex = /^(\d{0,2})(\d{0,7})$/;
                              const groups = input.match(regex);

                              if (groups) {
                                const formattedValue = !groups[2]
                                  ? groups[1]
                                  : `${groups[1]}-${groups[2]}${groups[3] ? `-${groups[3]}` : ''}`; 
                                handleChange({
                                  target: {
                                    name: 'taxNumber',
                                    value: formattedValue, 
                                  },
                                });
                              } else {
                                handleChange({
                                  target: {
                                    name: 'taxNumber',
                                    value: input, 
                                  },
                                });
                              }
                            }}
                            onBlur={handleBlur}
                            title="EIN / Tax ID Number"
                            maxLength="10"
                          />

                        {errors.taxNumber &&
                          touched.taxNumber ? (
                          <span className="form-error">
                            {errors.taxNumber}
                          </span>
                        ) : null}
                      </div>
                      
                      {/********************************* */}

                      <div className="col-md-6 mb-3">
                        <label className="form-label">Lead Time</label>
                        <div className="row">
                          <div className="col-md-12 mb-6">
                            <select
                              className="form-select"
                              onChange={(e) => {
                                setFieldValue("leadTime", e.target.value);
                              }}
                              value={values.leadTime}
                            >
                              <option value="5">5 Minutes</option>
                              <option value="10">10 Minutes</option>
                              <option value="15">15 Minutes</option>
                              <option value="20">20 Minutes</option>
                              <option value="25">25 Minutes</option>
                              <option value="30">30 Minutes</option>
                              <option value="35">35 Minutes</option>
                              <option value="40">40 Minutes</option>
                              <option value="45">45 Minutes</option>
                              <option value="50">50 Minutes</option>
                              <option value="55">55 Minutes</option>
                              <option value="60">60 Minutes</option>
                            </select>
                            {errors.leadTime && touched.leadTime ? (
                              <span className="form-error">
                                {errors.leadTime}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      

                      <div className="col-md-12 mb-3">
                        <label className="form-label">Notifications</label>
                        <div className="switch-box d-flex">
                          <div className="switch-box-content d-flex me-3">
                            <label className="sub-label me-2">Text</label>
                            <div className="switch-toggle-sm">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  name="notificationText"
                                  checked={values.notificationText}
                                  onChange={handleChange}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div className="switch-box-content d-flex">
                            <label className="sub-label me-2">Email</label>
                            <div className="switch-toggle-sm">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  name="notificationEmail"
                                  checked={values.notificationEmail}
                                  onChange={handleChange}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Policy</label>
                        <textarea
                          type="text"
                          name="policy"
                          placeholder="Enter Policy"
                          className={
                            "form-control textarea-box" +
                            (errors.policy && touched.policy
                              ? " is-invalid"
                              : "")
                          }
                          value={values.policy?.replace(/ +/g, " ")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Policy"
                        />
                        {errors.policy && touched.policy ? (
                          <span className="form-error">{errors.policy}</span>
                        ) : null}
                      </div>
                      <h4 className="sub-title-page mb-3 mt-3">Category</h4>
                      <div className="col-md-12 mb-6">
                        <label className="form-label">Service Category</label>
                        <Select
                          value={values?.categoryIds}
                          isMulti
                          onChange={(value) => {
                            setFieldValue("categoryIds", [...value]);
                          }}
                          options={categoryList}
                        />
                        {errors.categoryIds &&
                          touched.categoryIds ? (
                          <span className="form-error">
                            {errors.categoryIds}
                          </span>
                        ) : null}
                      </div>
                      <h4 className="sub-title-page mb-3 mt-3">
                        Bank Account Details
                      </h4>

                      <div className="col-md-12 mb-6 account-type-field">
                          <label className="form-label">Account Type</label>
                          <div className="inline-radio-input">
                              <div className="form-check form-check-inline">
                                  <input
                                      className="form-check-input"
                                      onChange={() => {
                                          setFieldValue("bankAccountType", "Checking");
                                          if (values.bankAccountType === "Checking" && values.duration === "") {
                                              setFieldValue("bankAccountType", "");
                                          }
                                      }}
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      checked={values.bankAccountType === "Checking"}
                                  />
                                  <label htmlFor="flexRadioDefault1">Checking</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input
                                      className="form-check-input"
                                      type="radio"
                                      onChange={() => {
                                          setFieldValue("bankAccountType", "Savings");
                                      }}
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      checked={values.bankAccountType === "Savings"}
                                  />
                                  <label htmlFor="flexRadioDefault2">Savings</label>
                              </div>
                          </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="form-label">Account Number</label>
                        <input
                          type="text"
                          name="bankAccountNumber"
                          placeholder="Enter account number"
                          className={
                            "form-control" +
                            (errors.bankAccountNumber &&
                              touched.bankAccountNumber
                              ? " is-invalid"
                              : "")
                          }
                          value={values.bankAccountNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Account Number"
                         // disabled={accountnumber? true : false}
                        />
                        {errors.bankAccountNumber &&
                          touched.bankAccountNumber ? (
                          <span className="form-error">
                            {errors.bankAccountNumber}
                          </span>
                        ) : null}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="form-label">Confirm Account Number</label>
                        <input
                          type="text"
                          name="confirmbankAccountNumber"
                          placeholder="Enter account number"
                          className={
                            "form-control" +
                            (errors.confirmbankAccountNumber &&
                              touched.confirmbankAccountNumber
                              ? " is-invalid"
                              : "")
                          }
                          value={values.confirmbankAccountNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Confirm Account Number"
                          onPaste={(e) => e.preventDefault()}
                         // disabled={accountnumber? true : false}
                        />
                        {errors.confirmbankAccountNumber &&
                          touched.confirmbankAccountNumber ? (
                          <span className="form-error">
                            {errors.confirmbankAccountNumber}
                          </span>
                        ) : null}
                      </div>                      
                      
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Routing Number</label>
                        <input
                          type="text"
                          name="routingNumber"
                          placeholder="Enter routing number"
                          className={
                            "form-control" +
                            (errors.routingNumber &&
                              touched.routingNumber
                              ? " is-invalid"
                              : "")
                          }
                          value={values.routingNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          title="Routing Number"
                          onPaste={(e) => e.preventDefault()}
                         // disabled={routingnumber? true : false}
                        />
                        {errors.routingNumber &&
                          touched.routingNumber ? (
                          <span className="form-error">
                            {errors.routingNumber}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-md-12 text-center mt-4 btn-group-box">
                        <button type="submit" className="btn btn-secondry max-w">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}
