import AxiosInterceptor from "../Utils/AxiosInterceptor";

const PREFIX = "coupon/";

export const getAllCouponTemplates = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `getAllCouponTemplates`,payload);
};

export const addCouponTemplate = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `addCouponTemplate`,payload);
};

export const updateCouponTemplate = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `updateCouponTemplate`,payload);
};

export const getCouponDetails = async (id) => {
    return await AxiosInterceptor().get(PREFIX + `getOneCouponTemplate/${id}`);
};

export const deleteCouponTemplate = async (id) => {
    return await AxiosInterceptor().get(PREFIX + `deleteCouponTemplate/${id}`);
};

export const changeStatus = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `changeStatus/`, payload);
};

export const publishCoupon = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `publishCoupon`,payload);
};

export const getAllCouponPublished = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `getAllCouponPublished`,payload);
};


export const getOneCouponPublished = async (id) => {
    return await AxiosInterceptor().get(PREFIX + `getOneCouponPublished/${id}`);
};

export const updateCouponPublished = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `updateCouponPublished`,payload);
};

export const changeStatusPublished = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `changeStatusPublished`, payload);
};

export const getCategories = async () => {
    return await AxiosInterceptor().get(PREFIX + `getCategories`);
};

export const getSharedCouponDetails = async (id) => {
    return await AxiosInterceptor().get(PREFIX + `getSharedCouponDetails/${id}`);
};