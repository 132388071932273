import React, { useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getCalendarDetails, getCouponTemplateList, redeemCoupon } from '../../../Services/CoupanCalenderViewServices';
import moment from 'moment';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { tostE, tostS } from '../../../Utils/Toast';
import { MEDIA_BASE_URL } from '../../../Utils/Constants';
import { getDateForMenu1, TextLimitShow } from '../../../Utils/CommonFunctions';
import { useSelector } from 'react-redux';
import { getBusinessDetails } from '../../../Services/Businessconfig';
import logo from '../../../Assets/images/logo.png'

export default function DashboardScreen() {
  const [loading, setLoading] = useState(false);
   const [startTime, setStartTime] = useState(null);
   const [calendardetails, setCalendarDetails] = useState([]);
   const [currentDate, setCurrentDate] = useState(moment());
   const [tocurrentDate, setTOCurrentDate] = useState(moment());
   const [showPopupleftclick, setShowPopupleftclick] = useState(false);
   const [showBookappoinment, setShowBookappoinment] = useState(false);
   const [showPopuanpublish, setShowPopuppublish] = useState(false);
   const [categoryList, setCategoryList] = useState([]);
   const [selectedCouponId, setSelectedCouponId] = useState(null);
   const [dateAndTime, setDateAndTime] = useState(null);
   const [selectedEvent, setSelectedEvent] = useState(null);
   const [showPopup, setShowPopup] = useState(false);
   const [redeemValue, setRedeemValue] = useState('');
   const [isInputEmpty, setIsInputEmpty] = useState(false);
   const [isInputInvalid, setIsInputInvalid] = useState(false);
   const [redeemedSuccessfully, setRedeemedSuccessfully] = useState(false);
   const [slotMinTime, setSlotMinTime] = useState('08:00:00');
   const [slotMaxTime, setSlotMaxTime] = useState('20:30:00');
   const [showMorePopup, setShowMorePopup] = useState(false);
   const [showMorePopupDetails, setShowMorePopupDetails] = useState(false);
   const [businessdetails, setBusinessdetails] = useState(null);
   const [viewMode, setViewMode] = useState('day');
   const [moreEvents, setMoreEvents] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const user = useSelector(state => state.user);
   const [username , setUsername] = useState('');
   const [selectedCouponIdPopup, setSelectedCouponIdPopup] = useState(null);
   const navigate = useNavigate();


   let i;
   const startTimebusniess = slotMinTime;
   const endTimebusniess = slotMaxTime;


   const handlePublicCouponTemplate = () => {
      navigate(`/merchant/my-coupon/coupon-template/list`);
   };
   const handleEditCoupon = () => {
      if(!selectedCouponIdPopup){
         return tostE("Please Select Edit Coupon")
      }
      navigate(`/merchant/my-coupon/coupon-template/update/${selectedCouponIdPopup}`);
   };
   const handlePublishCoupon = () => {
      if(!selectedCouponIdPopup){
         return tostE("Please Select publish Coupon")
      }
      navigate(`/merchant/my-coupon/coupon-template/publish/${selectedCouponIdPopup}`, {
         state: { dateAndTime: dateAndTime }
      });
   };

   const handleRedeem = () => {
      if (redeemValue.trim() === '') {
         setIsInputEmpty(true);
         return;
      }
      if (!/^[a-zA-Z0-9]{9}$/.test(redeemValue.trim())) {
         setIsInputInvalid(true);
         return;
      } else {
         setIsInputInvalid(false);
      }

      const today = new Date();
      const  getTimezoneOffset = Math.abs((today).getTimezoneOffset());    
      const payload = {
         id: redeemValue,
         offset:getTimezoneOffset,
         currentDate:today
      };
      redeemCoupon(payload).then((res) => {
         if (res.status === 200) {
            navigate(`/merchant/dashboard`);
            tostS(res?.data?.message);
            setShowPopup(false);
            setShowMorePopupDetails(false)
            setShowMorePopup(false);
            setRedeemedSuccessfully(true);
         }
      }).catch((err) => {
         if (err?.response?.data) {
            tostE(err?.response?.data?.message);
         }
      })
   };

   const handleChangeViewMode = (mode) => {
      setViewMode(mode);
      switch (mode) {
         case 'day':
            setCurrentDate(moment());
            break;
         case 'week':
             setCurrentDate(moment().startOf('isoWeek'));
            break;
         case 'month':
            setCurrentDate(moment(new Date()).startOf('month'));
            setTOCurrentDate(moment(new Date()).endOf('month'));
            break;
         default:
            break;
      }
   };

   const renderCalendarTitle = () => {
      switch (viewMode) {
         case 'day':
            return currentDate.format('dddd, MMMM Do, YYYY');
         case 'week':
            const startOfWeek = currentDate.clone().startOf('isoWeek').isoWeekday(1);
            const endOfWeek = currentDate.clone().endOf('isoWeek').isoWeekday(7);
            return `${startOfWeek.format('MMMM Do')} - ${endOfWeek.format('MMMM Do, YYYY')}`;
         case 'month':
            return currentDate.format('MMMM YYYY');
         default:
            return '';
      }
   };

   
   const fetchCalendarDetails = async (date) => {
      try {
         const response = await getCalendarDetails(date);
         if (response?.status === 200) {
            setTimeout(() => {
               setIsLoading(false);
               setLoading(false);
               const responseData = response?.data?.data;
               setCalendarDetails(responseData);
               if (responseData?.businessHourStartTime) {
                  let slotStartTime = responseData.businessHourStartTime;
                  // Parse the time using moment
                  let slotStartMoment = moment(slotStartTime, 'HH:mm:ss');
                  // Get the minutes part
                  let minutes = slotStartMoment.minutes();
                  // Round to the nearest half hour
                  if (minutes <= 29) {
                     slotStartMoment.minutes(0);
                  } else {
                     slotStartMoment.minutes(30);
                  }
                  // Format the time back to HH:mm:ss
                  slotStartTime = slotStartMoment.format('HH:mm:ss');
                  setSlotMinTime(slotStartTime);
                  
               }
               if (responseData?.businessHourEndTime) {
                  const slotEndTime = moment(responseData?.businessHourEndTime, 'HH:mm:ss');
                  const slotEndTimeWithExtra = slotEndTime.add(0, 'minutes').format('HH:mm:ss');
                  setSlotMaxTime(slotEndTimeWithExtra);
               }
            }, 500);
         }
      } catch (error) {
      } finally {
         setTimeout(() => {
            setLoading(false);
         }, 1000);
      };
   };


   let formattedDate;
   let toDateformate;

   if (viewMode == "day") {
      formattedDate = moment(currentDate).format('YYYY-MM-DD');
      toDateformate = moment(currentDate).format('YYYY-MM-DD');
   } else if (viewMode == "week") {
      formattedDate = moment(currentDate).startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DD');
      toDateformate = moment(currentDate).endOf('isoWeek').isoWeekday(7).format('YYYY-MM-DD');
   } else {
      formattedDate = moment(currentDate).startOf('month').format('YYYY-MM-DD');
      toDateformate = moment(currentDate).endOf('month').format('YYYY-MM-DD');
   }

   const defaultTime = '00:01:01';
   const defaultTime1 = '23:59:59'; 
   const fromDateWithDefaultTime = `${formattedDate} ${defaultTime}`;
   const toDateWithDefaultTime = `${toDateformate} ${defaultTime1}`;
   
   let todaynewdate = new Date()
   const date = {
      fromDate: new Date(fromDateWithDefaultTime),
      toDate: new Date(toDateWithDefaultTime),
      timezone : Intl.DateTimeFormat().resolvedOptions().timeZone,
      currentDate:todaynewdate
   };


   useEffect(() => {
      getCouponTemplateList().then((res) => {
         if (res.status === 200) {
            const resData = res.data.data;
            setCategoryList(resData)
            setSelectedCouponIdPopup(resData[0].id);  

         }
      }).catch((err) => {
         tostE(err?.response?.data?.message);
      })
   }, [])
   

   useEffect(() => {
      fetchCalendarDetails(date);

      getEventsForDate()
      setIsLoading(true);
      if (redeemedSuccessfully) {
         setShowPopup(false);
         setShowMorePopupDetails(false)
         setShowMorePopup(false);
         fetchCalendarDetails(date)
      }

   }, [currentDate, redeemedSuccessfully, viewMode]);


   const handleDateChange = (date) => {
      let newCurrentDate = moment(date);
      let endOfMonth;
      if (!newCurrentDate.isValid()) {
         return;
      }
      if (viewMode === 'week') {
         newCurrentDate = newCurrentDate.startOf('week');
         endOfMonth = newCurrentDate.endOf('week');
      } else if (viewMode === 'month') {
         newCurrentDate = newCurrentDate.clone().startOf('month');
         endOfMonth = newCurrentDate.clone().endOf('month');
      }
      setCurrentDate(newCurrentDate);
      setTOCurrentDate(endOfMonth)
   };


   const getToDate = (date) => {
      if (viewMode === 'week') {
         return moment(date).endOf('week').format('YYYY-MM-DD');
      } else if (viewMode === 'month') {
         return moment(date).endOf('month').format('YYYY-MM-DD');
      } else {
         return date.format('YYYY-MM-DD');
      }
   };

   const getEventsForDate = (calendardetails) => {
      const events = calendardetails?.finalResult?.map(item => {
         let Active = getDateForMenu1(item?.date)._d;
         let Purchased = getDateForMenu1(item?.redeemDate)._d; 

         let slotStartTime = moment.utc(item?.slotStartTime , "HH:mm:ss").local().format('HH:mm:ss');
         slotStartTime = moment(slotStartTime, 'HH:mm:ss');
        
         let hours = slotStartTime.hours();
         let minutes = slotStartTime.minutes();

         if (viewMode === 'week') {
            if (minutes <= 29) {
               minutes = '01';
            } else {
               minutes = '31';
            }
            if (hours <= 9) {
               hours = `0${hours}`;
            }
         } else {
            if (minutes <= 29) {
               minutes = '00';
            } else {
               minutes = '30';
            }
            if (hours <= 9) {
               hours = `0${hours}`;
            }
         }

         let startdate;
         if (item?.type === "Active") {
            startdate = moment.utc(Active ,"YYY-MM-DD").format('YYYY-MM-DD') + 'T' + hours + ':' + minutes + ':00';
         } else if (item?.type === "Purchased") {
            startdate = moment.utc(Purchased,"YYY-MM-DD").format('YYYY-MM-DD') + 'T' + hours + ':' + minutes + ':00';
         }
         else if (item?.type === "Redeemed") {
            startdate = moment.utc(Purchased,"YYY-MM-DD").format('YYYY-MM-DD') + 'T' + hours + ':' + minutes + ':00';
         } else if (item?.type === "Expired") {
            startdate = moment.utc(Purchased,"YYY-MM-DD").format('YYYY-MM-DD') + 'T' + hours + ':' + minutes + ':00';
         }

         slotStartTime = moment.utc(item?.slotStartTime , "HH:mm:ss").local().format('HH:mm:ss');
         return {
            title: item?.title + ` (${item.numberOfSlots ? item?.numberOfSlots : item?.qty ? item?.qty: '0' })`,
            titlepopup: item?.title,
            start: startdate,
            end: null,
            type: item?.type,
            slotStartTime: slotStartTime,
            couponid: item?.id,
            numberOfSlots: item?.numberOfSlots,
            qty: item?.qty,
            allRedeemed: item?.allRedeemed,
            allExpired: item?.allExpired,
         };
      });
      return events;
   };


   const generateTimeSlots = () => {
      const tableRows = [];
      const startHour = parseInt(startTimebusniess.split(':')[0]);
      const startMinute = parseInt(startTimebusniess.split(':')[1]);
      const endHour = parseInt(endTimebusniess.split(':')[0]);
      const endMinute = parseInt(endTimebusniess.split(':')[1]);
      const currentTime = moment();

      const events = calendardetails && calendardetails.finalResult ? getEventsForDate(calendardetails, currentDate) : [];

      let currentHour = startHour;
      let currentMinute = startMinute;

      for (let i = currentHour; i <= endHour && i <= 24; i++) {
         for (let j = 0; j < 2; j++) {
            const slotTime = moment(currentDate).set({ 'hour': i, 'minute': currentMinute, 'second': 0 });
            if (slotTime.isBefore(moment(currentDate).set({ 'hour': startHour, 'minute': startMinute, 'second': 0 }))) {
               continue;
            }
            const isPast = slotTime.isBefore(currentTime, 'minute');

            const eventsForSlot = events.filter(event => {
               const eventStartTime = event.start;
               const eventEndTime = event.end;
               return (eventEndTime && slotTime.isBetween(eventStartTime, eventEndTime, null, '[]', '[)')) ||
                  (!eventEndTime && slotTime.isSame(eventStartTime, 'minute'));
            });


            const hasMoreEvents = eventsForSlot.length > 1;
            const hasEvents = eventsForSlot.length > 0;

            tableRows.push(
               <tr key={`row-${i}-${j}`}>
                  <td className={`table-left-box ${isPast ? 'caleender-past-dates' : ''}`} onDoubleClick={() => handleTimeSlotDoubleClick(slotTime)}>
                     {slotTime.format('hh:mm A')}
                  </td>
                  <td className={`${isPast ? 'caleender-past-dates' : ''}`} onDoubleClick={() => handleTimeSlotDoubleClick(slotTime)}>
                     <div className="event-value">
                        {hasEvents &&
                           (hasMoreEvents ? (
                              <>
                                 {eventsForSlot.slice(0, 1).map((event, index) => (
                                    <span 
                                          key={index} 
                                          className={getEventClass(event.type)}
                                          onClick={() => handleEventClick(event)}
                                    >
                                       {event.title}
                                    </span>
                                 ))}
                                 <samp onClick={() => handleMoreEventsClick(eventsForSlot)}>
                                     +({eventsForSlot.length - 1}) More
                                 </samp>
                              </>
                           ) : (
                              eventsForSlot.map((event, index) => (
                                 <span key={event.id} onClick={() => handleEventClick(event)} className={getEventClass(event.type)}>
                                    {event.title}
                                 </span>
                              ))
                           ))
                        }
                     </div>
                  </td>
               </tr>
            );
            currentMinute += 30;
            if (currentMinute >= 60) {
               currentMinute -= 60;
               currentHour += 1;
            }
         }
      }
      return tableRows;
   };

   const generateWeekTimeSlots = () => {
      const tableRows = [];
      const currentTime = moment();
      const startOfWeek = moment(currentDate).startOf('week').isoWeekday(8); // Set Monday as start of the week
   
      const startHour = parseInt(startTimebusniess.split(':')[0]);
      const startMinute = parseInt(startTimebusniess.split(':')[1]);
      const endHour = parseInt(endTimebusniess.split(':')[0]);
      const endMinute = parseInt(endTimebusniess.split(':')[1]);
      const adjustedStartMinute = startMinute >= 30 ? 30 : 0;
      
      const events = calendardetails && calendardetails.finalResult ? getEventsForDate(calendardetails, currentDate) : [];
      tableRows.push(
         <tr key="header">
            <td></td>
            {[...Array(7).keys()].map(day => {
               const currentDateOfWeek = moment(startOfWeek).add(day, 'days');
               return (
                  <td key={`header-${day}`} colSpan="2">{currentDateOfWeek.format("ddd DD")}</td>
               );
            })}
         </tr>
      );
   
      for (let i = startHour; i <= endHour && i <= 24; i++) {
         for (let j = 0; j < 60; j += 30) {
       
            if (i === startHour && j < adjustedStartMinute) continue;
   
            const slotStart = moment().set({ 'hour': i, 'minute': j, 'second': 0 });
            const slotEnd = moment().set({ 'hour': i, 'minute': j + 30, 'second': 0 });
            const isCurrentTimeInRange = currentTime.isBetween(slotStart, slotEnd);
   
            tableRows.push(
               <tr key={`row-${i}-${j}`}>
                  <td className={`table-left-box`}>
                     {slotStart.format('hh:mm A')}
                  </td>
                  {[...Array(7).keys()].map(day => {
                     const currentDate = moment(startOfWeek).add(day, 'days');
                     const slotDateTime = moment().set({ 'year': currentDate.year(), 'month': currentDate.month(), 'date': currentDate.date(), 'hour': i, 'minute': j, 'second': 0 });
                     const isSlotPast = slotDateTime.isBefore(currentTime);
                     const eventsForSlot = events.filter(event => {
                        const eventStartTime = moment(event.start);
                        const eventDate = moment(event.start).startOf('day');
                        if (eventDate.isSame(moment(currentDate).startOf('day'))) {
                           const slotStart = moment().set({ 'year': currentDate.year(), 'month': currentDate.month(), 'date': currentDate.date(), 'hour': i, 'minute': j, 'second': 0 });
                           const slotEnd = slotStart.clone().add(30, 'minutes');
                           if (eventStartTime.isBetween(slotStart, slotEnd, null, '[]')) {
                              return true;
                           }
                        }
                        return false;
                     });
   
                     const hasMoreEvents = eventsForSlot.length > 1;
                     const hasEvents = eventsForSlot.length > 0;
   
                     return (
                        <td key={`cell-${day}-${i}-${j}`} colSpan="2" className={`${isSlotPast ? 'caleender-past-dates' : ''} ${isCurrentTimeInRange ? 'current-hour' : ''}`} 
                           onDoubleClick={() => handleDoubleClick(currentDate, slotStart)}>
                           <div className="event-value" >
                              {hasEvents &&
                                 (hasMoreEvents ? (
                                    <>
                                       {eventsForSlot.slice(0, 1).map((event, index) => (
                                          <span key={index} 
                                                onClick={() => handleEventClick(event)} 
                                                className={getEventClass(event.type)}
                                          >
                                             {event.title}
                                          </span>
                                       ))}
                                          <p>
                                             <samp onClick={() => handleMoreEventsClick(eventsForSlot)}>
                                                +({eventsForSlot.length - 1}) more
                                             </samp>
                                          </p>
                                    </>
                                 ) : (
                                    eventsForSlot.map((event, index) => (
                                       <span key={index} onClick={() => handleEventClick(event)} className={getEventClass(event.type)} >
                                          {event.title}
                                       </span>
                                    ))
                                 ))
                              }
                           </div>
                        </td>
                     );
                  })}
               </tr>
            );
         }
      }
      return tableRows;
   };
   
   const generateMonthCalendar = () => {
      let currentDate1 = new Date(currentDate);
      let tocurrentDate1 = new Date(tocurrentDate ? tocurrentDate : currentDate);
      const tableRows = [];
      const today = moment().startOf('day');
      const startOfMonth = moment(currentDate1).startOf('month');
      const endOfMonth = moment(tocurrentDate1).endOf('month');
      const events = calendardetails && calendardetails.finalResult ? getEventsForDate(calendardetails, currentDate) : [];

      const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      tableRows.push(
         <tr>
            {daysOfWeek.map(day => (
               <td key={`header-${day}`}>{day}</td>
            ))}
         </tr>
      );

      let currentWeek = [];
      let currentDay = startOfMonth.clone();
      let isFirstWeek = true;

      while (currentDay.isSameOrBefore(endOfMonth, 'day')) {
         if (isFirstWeek) {
            while (currentDay.isoWeekday() !== 1) {
               currentWeek.push(currentDay.clone().subtract(1, 'week'));
               currentDay.subtract(1, 'day');
            }
            isFirstWeek = false;
         }
         if (currentDay.month() === startOfMonth.month()) {
            currentWeek.push(currentDay.clone());
         }

         if (currentDay.isoWeekday() === 7 || currentDay.isSame(endOfMonth, 'day')) {
            if (currentWeek.length > 0) {
               tableRows.push(
                  <tr key={`${currentWeek[0].week()}`} >
                     {currentWeek.map(day => {
                        const isPast = day.isBefore(today, 'day');
                        const isCurrentMonth = day.month() === startOfMonth.month();
                        const cellText = isCurrentMonth ? day.format('D') : '';
                        const eventsForDay = events.filter(event => moment(event.start).isSame(day, 'day'));
                        return (
                        <td key={`day-${day.format('DD')}`}
                           className={isPast ? 'caleender-past-dates' : ''}
                           onDoubleClick={isCurrentMonth ? () => !isPast && handleDateDoubleClick(day) : null}  >
                           <span className='datemonthcalender'>{cellText}</span>
                           {eventsForDay.map((event, index) => (
                              <>
                                 {index < 1 && (
                                    <div className="event-value">
                                    <span key={index}
                                       className={getEventClass(event.type)}
                                       onClick={() => handleEventClick(event)}
                                    >
                                       {event.title}
                                    </span>
                                    </div>
                                 )}
                                 {index === 1 && eventsForDay.length > 1 && (
                                    <div className="event-value">
                                    <samp className="" onClick={() => handleMoreEventsClick(eventsForDay)}>
                                       +({eventsForDay.length - 1}) more
                                    </samp>
                                    </div>
                                 )}
                              </>
                           ))}
                        </td>
                     );
                  })}
               </tr>
            );
            currentWeek = [];
         }
      }
      currentDay.add(1, 'day');
   }
   return tableRows;
};

   const handleTimeSlotDoubleClick = (slotTime) => {
      const currentTime = moment();
      if (slotTime.isSameOrAfter(currentTime)) {
         setShowPopupleftclick(true)
         let clickedDateTime = new Date(slotTime)
         const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
         const dateAndTime = {
            date: clickedDateTime.toLocaleDateString('en-US', dateOptions),
            time: clickedDateTime.toLocaleTimeString(),
            dateTime: clickedDateTime
         };
         setDateAndTime(dateAndTime);
      }
   };

   const handleDoubleClick = (slotDate, slotTime) => {
      const currentTime = moment();
      const clickedDateTime = moment(slotDate).set({ 'hour': slotTime.hour(), 'minute': slotTime.minute(), 'second': 0 });
      if (clickedDateTime.isAfter(currentTime)) {

         setShowPopupleftclick(true)
         let weektimedate = new Date(clickedDateTime)
         const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
         const dateAndTime = {
            date: weektimedate.toLocaleDateString('en-US', dateOptions),
            time: weektimedate.toLocaleTimeString(),
            dateTime: weektimedate
         };
         setDateAndTime(dateAndTime);
      }
   };

   const handleDateDoubleClick = (date) => {
      setShowPopupleftclick(true)
      let weektimedate = new Date(date)
      const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
      const dateAndTime = {
         date: weektimedate.toLocaleDateString('en-US', dateOptions),
         time: weektimedate.toLocaleTimeString(),
         dateTime: weektimedate
      };
      setDateAndTime(dateAndTime);
   };

   const getEventClass = (eventType) => {
      switch (eventType) {
         case "Purchased":
            return 'green';
         case "Active":
            return 'orange';
         case "Expired":
            return 'red';
         case "Redeemed":
            return 'dark-green';
         default:
            return '';
      }
   };

   const closePopup = () => {
      setShowPopupleftclick(false);
      setShowBookappoinment(false);
      setShowPopuppublish(false)
      setShowPopup(false)
      setRedeemValue('');
      setIsInputEmpty(false);
      setIsInputInvalid(false);
   };

   const ShowPopuppublish = () => {
      setShowPopuppublish(true);
      setShowPopupleftclick(false);
   };

   const showBookappoinmentfun = () => {
      setShowBookappoinment(true);
      setShowPopupleftclick(false);
   };

   const handleEventClick = (event) => {
      setSelectedEvent(event);
      setShowPopup(true);
   };

   const handleMoreEventsClick = (events) => {
      setMoreEvents(events);
      setShowMorePopup(true);
   };

   const closeMorePopup = () => {
      setMoreEvents([]);
      setShowMorePopup(false);
   };

   const handleMoreEventsClickddetails = (event) => {
      //setMoreEvents(events);
      setSelectedEvent(event);
      setShowMorePopupDetails(true);
      setShowMorePopup(false);
      setRedeemValue('');
      setIsInputEmpty(false);
      setIsInputInvalid(false);
   };


   const closeMorePopuploop = () => {
         setShowMorePopupDetails(false)
         setShowMorePopup(true);
   };
   

   useEffect(() => {
      setStartTime(new Date());
      setLoading(true);  
      const fetchCouponDetails = async () => {     
          try {                
              const response = await getBusinessDetails();
              if (response.status === 200) {
                  
                  setTimeout(() => {
                      setLoading(false); 
                      setStartTime(null); 
                      setBusinessdetails(response?.data?.data);
                  }, 500); 

              } else {
                  setLoading(false); 
                  setStartTime(null); 
                  tostE('Failed to fetch coupon details:', response.statusText);
              }
          } catch (error) {
              setLoading(false);
              setStartTime(null); 
          } finally {
              setTimeout(() => {
                  setLoading(false); 
                  setStartTime(null); 
              }, 1000);}
      };
      setUsername(user.data)
   
      fetchCouponDetails();
  }, []);

  return (
    <div className="dashboard-sec section-space">
      <div className="container">
      {loading && (
          <div className="preload">
            <img src={logo} alt="Insta-coupon Logo" className="preloadlogo" />
          </div>
        )}
         {businessdetails?.bankAccountNumber? '':
            <div className="page-title mb-4">
               <p className="incomplete-detail-message">
                  Hello! {username?.representName} It's time to give your <Link  to={"/merchant/business-config/update"}>Business Configurations </Link>some attention. Keeping them updated ensures everything runs smoothly and efficiently.                  
               </p>
            </div>
         
         }
        <div className="page-title mb-4">
          <h1>Dashboard</h1>
        </div>
        <div className="dashboard-info-main">
          <div className="row">

                    <div className="col-md-3">
                        <div className="dashboard-info d-flex justify-content-between align-items-center yallow-dash-info">
                           <div className="dashboard-info-left">
                              <h3>{calendardetails?.activeCoupons}</h3>
                              <p>Active Coupons</p>
                           </div>
                           <div className="dashboard-info-right">
                              <span>
                                 <i className="ti ti-ticket"></i>
                              </span>
                           </div>
                        </div>
                        <div className="backshadow"></div>
                     </div>
                     <div className="col-md-3">
                        <div className="dashboard-info d-flex justify-content-between align-items-center green-clender-dash-info">
                           <div className="dashboard-info-left">
                              <h3>{calendardetails?.purchaseCoupons}</h3>
                              <p>Purchased Coupons </p>
                           </div>
                           <div className="dashboard-info-right">
                              <span>
                                 <i className="ti ti-ticket"></i>
                              </span>
                           </div>
                        </div>
                        <div className="backshadow green-clender-dash-info"></div>
                     </div>
                     <div className="col-md-3">
                        <div className="dashboard-info d-flex justify-content-between align-items-center pink-dash-info">
                           <div className="dashboard-info-left">
                              <h3>{calendardetails?.expiredCoupons}</h3>
                              <p>Expired Coupons</p>
                           </div>
                           <div className="dashboard-info-right">
                              <span>
                                 <i className="ti ti-ticket"></i>
                              </span>
                           </div>
                        </div>
                        <div className="backshadow pink-shadow"></div>
                     </div>
                     <div className="col-md-3">
                        <div className="dashboard-info d-flex justify-content-between align-items-center green-light-dash-info">
                           <div className="dashboard-info-left">
                              <h3>{calendardetails?.redeemCoupons}</h3>
                              <p>Redeemed Coupons</p>
                           </div>
                           <div className="dashboard-info-right">
                              <span>
                                 <i className="ti ti-ticket"></i>
                              </span>
                           </div>
                        </div>
                        <div className="backshadow green-light-dash-info-shadow"></div>
                     </div>
              {calendardetails?.popularCouponResult?.length === 0 ? '':    
               <>  
               <div className="box-shadow mt-5">
                 <div className="section-title mb-4">
                     <h2>Popular Coupons</h2>
                  </div>
                  <div className="row">
                  {calendardetails?.popularCouponResult?.slice(0, 3).map((coupon, index) => 
                        <div className="col-md-4 coupan-box Popular-coupan-box" key={index}>
                           <div className="box-shadow p-3 d-flex" >
                              <figure>
                                 <img src={MEDIA_BASE_URL + coupon?.couponImage} alt="coupon-img" />
                              </figure>
                              <figcaption>
                                 <div className="coupan-content-head d-flex align-items-center justify-content-between pb-1">
                                    <h4 className="pb-0">{coupon?.title}</h4>
                                    <b className="price-text">${coupon?.price}</b>
                                 </div>
                                 <span className="date-text">
                                 {/* <i className="ri-calendar-line"></i> {getDateForMenu(coupon?.availableDate ? coupon?.availableDate : coupon?.endDateTime)}{', '}{formatDate(coupon?.slotStartTime, coupon?.duration, coupon?.durationText)} */}
                                 </span>
                                 <span className="discount-text mb-0">Discount of {coupon?.disPercentage}%</span>
                              </figcaption>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
               </>
            }            
            <div className="calender-box mt-4">
                  <figure>
                     <div className="coupan-sec-calender">
                        <div class="calendar-tab-head">
                           <h2>Calendar</h2>
                           <div className="tab-head">
                              <ul>
                                 <li className={viewMode === "day" ? "active mode-cursion-calender" : 'mode-cursion-calender'}>
                                    <a onClick={() => handleChangeViewMode('day')}>Daily</a>
                                 </li>
                                 <li className={viewMode === "week" ? "active mode-cursion-calender" : 'mode-cursion-calender'}>
                                    <a 
                                    onClick={() => handleChangeViewMode('week')}
                                    >Weekly</a>
                                 </li>
                                 <li className={viewMode === "month" ? "active mode-cursion-calender" : 'mode-cursion-calender'}>
                                    <a onClick={() => handleChangeViewMode('month')}>Monthly</a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div id="calendar" className="calendar-custom fc fc-media-screen fc-direction-ltr fc-theme-standard">
                           <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr custom-toolbar-box">
                              <div className="fc-toolbar-chunk">
                                 <button
                                    type="button"
                                    //title="Previous month"
                                    aria-pressed="false"
                                    className="fc-prev-button fc-button fc-button-primary"
                                    onClick={() => handleDateChange(moment(currentDate).subtract(1, viewMode))}
                                 >
                                    <span className="fc-icon fc-icon-chevron-left" role="img"></span>
                                 </button>
                              </div>
                              <div className="fc-toolbar-chunk">
                                 <h2 className="fc-toolbar-title" id="fc-dom-1456">
                                    {renderCalendarTitle()}
                                 </h2>
                              </div>
                              <div className="fc-toolbar-chunk">
                                 <div className="fc-button-group">
                                    <button
                                       type="button"
                                      // title="Next month"
                                       aria-pressed="false"
                                       className="fc-next-button fc-button fc-button-primary"
                                       onClick={() => handleDateChange(moment(currentDate).add(1, viewMode))}
                                    >
                                       <span className="fc-icon fc-icon-chevron-right" role="img"></span>
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className='custom-calender-view-header'>
                           <ul>
                              <li className='orange'><span>Active</span></li>
                              <li className='green'><span>Purchased</span></li>
                              <li className='red'><span>Expired</span></li>
                              <li className='darkgreen'><span>Redeemed</span></li>
                           </ul>
                        </div>

                        {isLoading ?
                            <div className="skeleton-loader">
                            <div className="skeleton-header"></div>
                            <div className="skeleton-content"></div>
                            <div className="skeleton-content"></div>
                            <div className="skeleton-content"></div>
                            <div className="skeleton-content"></div>
                         </div>  :
                        
                           viewMode === 'day' ?
                              <div className="day-table-box">
                                 <div className='calender-table-box'>
                                    <table className="table">
                                       {generateTimeSlots()}
                                    </table>
                                 </div>
                              </div>
                              : ''
                           }

                        {isLoading ?
                           <div className="skeleton-loader">
                           <div className="skeleton-header"></div>
                           <div className="skeleton-content"></div>
                           <div className="skeleton-content"></div>
                           <div className="skeleton-content"></div>
                           <div className="skeleton-content"></div>
                        </div>  :
                           viewMode === 'week' ?
                           <div className="weekly-table-box">
                              <div className='calender-table-box'>
                                 <table className="table">
                                    {generateWeekTimeSlots()}
                                 </table>
                              </div>
                           </div>
                           : ''}
                         {isLoading ?
                           <div className="skeleton-loader">
                              <div className="skeleton-header"></div>
                              <div className="skeleton-content"></div>
                              <div className="skeleton-content"></div>
                              <div className="skeleton-content"></div>
                              <div className="skeleton-content"></div>
                           </div> :viewMode === 'month' ?
                           <div className="monthly-table-box">
                              <div className='calender-table-box'>
                                 <table className="table">
                                    {generateMonthCalendar()}
                                 </table>
                              </div>
                           </div>
                           : ''}
                     </div>
                  </figure>
            </div>
          </div>
        </div>
      </div>




      <Modal className="modal fade common-modal delete-box-style" show={showPopupleftclick} backdrop="static">
            <div className="modal-head text-center mb-2">
               <h1 className="modal-title " id="exampleModalLabel">Select Your Action </h1>
               <button type="button" onClick={() => { closePopup() }} className="btn-close" aria-label="Close"></button>
            </div>
            <ModalBody>
               <div className="modal-foot mt-2 text-center">
                  <button onClick={() => { ShowPopuppublish() }} className="btn btn-primary min-sm-w">Publish Coupon</button>
                  <button onClick={() => { showBookappoinmentfun() }} className="btn btn-secondry min-sm-w ms-2">Add Appointment</button>
               </div>
            </ModalBody>
         </Modal>

         <Modal className="modal fade common-modal delete-box-style" show={showBookappoinment} backdrop="static">
            <div className="modal-head text-center mb-2">
               <h1 className="modal-title " id="exampleModalLabel">Add Appointment</h1>
               <button type="button" onClick={() => { closePopup() }} className="btn-close" aria-label="Close"></button>
            </div>
            <ModalBody>
               <h1 className="model-sm-title model-custom-title">Coming Soon</h1>
            </ModalBody >
         </Modal>


         <Modal className="modal fade common-modal delete-box-style" show={showPopuanpublish} backdrop="static">
            <div className="modal-head text-center mb-2">
               <h1 className="modal-title " id="exampleModalLabel">Add Coupon to Slot</h1>
               <button 
                        type="button" 
                        onClick={() => { closePopup() }} 
                        className="btn-close" 
                        aria-label="Close"
               >
               </button>
            </div>
            <ModalBody>
               {Array.isArray(categoryList) && categoryList.length > 0 ? (
                  <h4 className="model-sm-title">Select Coupon to add</h4>
               ) : ''}
               <ul className="coustom-check-list">
                  {categoryList.map((item, index) =>
                     <li key={item.id}>
                        <div className="form-check coustom-check" onClick={() => setSelectedCouponIdPopup(item.id)}>
                           <input 
                           className="form-check-input" 
                           type="radio" 
                           name="flexRadioDefault" 
                           id={`flexRadioDefault${index}`} 
                           checked={selectedCouponIdPopup === item.id} // Check if this is the selected coupon
                           onChange={() => setSelectedCouponIdPopup(item.id)} // Update selected coupon on change
                        />
                           <label className="form-check-label" htmlFor={`flexRadioDefault${index}`}>
                              <div className="box-shadow coupan-box Popular-coupan-box p-3 d-flex" onClick={() => setSelectedCouponIdPopup(item.id)}>
                                 <figure><img src={MEDIA_BASE_URL + item.couponImage} alt="coupon" /></figure>
                                 <figcaption>
                                    <div className="coupan-content-head d-flex align-items-center justify-content-between pb-1">
                                       <h4 className="pb-0">{item.title}</h4>
                                       <b className="price-text">${item.price}</b>
                                    </div>
                                    <span className="discount-text mb-0">Discount of {item.disPercentage}%</span>
                                    <p className="discount-text mb-0">{TextLimitShow(item.description, 12)}</p>
                                 </figcaption>
                              </div>
                           </label>
                        </div>
                     </li>
                  )}
               </ul>
            </ModalBody>
            {Array.isArray(categoryList) && categoryList.length > 0 ? (
               <div className="modal-foot mt-2 text-center">
                  <button className="btn btn-primary min-sm-w calenderedit-coupan-temp" onClick={handleEditCoupon}>
                     Edit Coupon Template
                  </button>
                  <button className="btn btn-secondry min-sm-w ms-2" onClick={handlePublishCoupon}>
                     Publish Coupon
                  </button>
               </div>
            ) :
               <div className="modal-foot mt-2 text-center">
                  <p>No Coupons published yet. Please start with publishing your first Coupon by going to Coupon Templates.</p><br />
                  <button className="btn btn-secondry min-sm-w calenderedit-coupan-temp" onClick={handlePublicCouponTemplate}>Coupon Template</button>
               </div>}
         </Modal>

         <Modal className="modal fade common-modal delete-box-style" show={showPopup} backdrop="static">
            <ModalBody>
               <div className="modal-head text-center mb-2">
                  <h1 className="modal-title" id="exampleModalLabel">Coupon Details</h1>
                  <button  type="button" 
                           onClick={closePopup} 
                           className="btn-close" 
                           aria-label="Close"
                  >
                  </button>
               </div>
               <div className="modal-body">
                  <ul className="coupon-detail-info">
                     <li className='title-calender-model'>
                        <b >Title</b>
                        <p className='title-text-calender-model'>{selectedEvent?.titlepopup}</p>
                     </li>
                     <li>
                        <b>Type</b>
                        <p>{selectedEvent?.type}</p>
                     </li>
                     {/* <li> */}
                        {selectedEvent?.numberOfSlots ?
                          <li>
                              <b>Number Of Slots</b>
                              <p>{selectedEvent?.numberOfSlots}</p>
                              </li>
                           : null}

                        {selectedEvent?.qty ?
                           <li>
                              <b>Number Of Slots</b>
                              <p>{selectedEvent?.qty ? selectedEvent?.qty : "0"}</p>
                              </li>
                           : null}
                     {/* </li> */}
                     <li>
                        <b>Slots Start Time</b>
                        <p>{moment(selectedEvent?.slotStartTime, 'HH:mm:ss').format('h:mm A')
                        }</p>
                     </li>
                     {selectedEvent?.type === 'Purchased' ?
                        <div className='redeemcoupan-box'>
                           {selectedEvent?.allRedeemed === true ? (
                              <>
                                 <b>Attention:</b> <span> Slots for Today Are Fully Redeemed</span>
                              </>
                           ) : selectedEvent?.allExpired === true ? (
                              <>
                                 <b>Attention:</b><span> Oops! It looks like the coupon purchase time has closed.</span>
                              </>
                           ) : (
                              <>
                                 <p>Coupon ID</p>
                                 <input
                                    className="redeem-coupan-id-field"
                                    type="text"
                                    id="redeem-value-id"
                                    value={redeemValue}
                                    onChange={(e) => {
                                       const inputValue = e.target.value;
                                       // Only allow alphanumeric characters and ensure the length is 9
                                       if (/^[a-zA-Z0-9]{0,9}$/.test(inputValue)) {
                                         setRedeemValue(inputValue);
                                         setIsInputEmpty(inputValue.length === 0);
                                         setIsInputInvalid(inputValue.length !== 9);
                                       }
                                     }}
                                    pattern="[a-zA-Z0-9]{9}"
                                    placeholder='Enter Coupon ID'
                                 />
                                 <br />
                                 {isInputEmpty && <span className="form-error">This field is required.</span>}
                                 {isInputInvalid && <span className="form-error">Invalid Coupon ID.</span>}
                              </>
                           )}

                        </div>
                        : ''}
                  </ul>

               </div>

               {selectedEvent?.type === 'Purchased' ?
                  selectedEvent?.allRedeemed === true ? (
                     ''
                  ) : selectedEvent?.allExpired === true ? (
                     ''
                  ) : (
                     <>
                        <div className="modal-foot mt-2 text-center">
                           <button className="btn btn-secondry min-w ms-2" onClick={handleRedeem}>
                              Redeem
                           </button>
                        </div>
                     </>
                  )
                  : null}
            </ModalBody>
         </Modal>
         <Modal className="modal fade common-modal delete-box-style" show={showMorePopup} backdrop="static">

            <div className="modal-head text-center mb-2">
               <h1 className="modal-title " id="exampleModalLabel">More Events</h1>
               <button type="button" onClick={closeMorePopup} className="btn-close" aria-label="Close"></button>
            </div>
            <ModalBody>
               <ul className="coustom-check-list">
                  {moreEvents.map((event, index) => (
                     <li key={event.id}>
                        <div className="form-check coustom-check" onClick={() => handleMoreEventsClickddetails(event)}>
                           <label className="form-check-label" htmlFor={`flexRadioDefault${index}`}>
                              <div className="calender-events-details box-shadow coupan-box Popular-coupan-box p-3 d-flex" onClick={() => setSelectedCouponId(event.id)}>
                                 <figcaption>
                                    <div className="event-value coupan-content-head d-flex align-items-center justify-content-between pb-1">
                                       <span className={getEventClass(event.type)} >
                                          <h4 className={getEventClass(event.type)}>{event.title}</h4>
                                          <b className="price-text">{event.type}</b>
                                       </span>
                                    </div>
                                 </figcaption>
                              </div>
                           </label>
                        </div>
                     </li>
                  ))}
               </ul>
            </ModalBody>
         </Modal>

         <Modal className="modal fade common-modal delete-box-style" show={showMorePopupDetails} backdrop="static">
            <ModalBody>
               <div className="modal-head text-center mb-2">
                  <h1 className="modal-title" id="exampleModalLabel">Coupon Details</h1>
                  <button  type="button" 
                           onClick={closeMorePopuploop} 
                           className="btn-close" 
                           aria-label="Close"
                  >
                  </button>
               </div>
               <div className="modal-body">
                  <ul className="coupon-detail-info">
                     <li>
                        <b>Title</b>
                        <p>{selectedEvent?.titlepopup}</p>
                     </li>
                     <li>
                        <b>Type</b>
                        <p>{selectedEvent?.type}</p>
                     </li>
                     {/* <li> */}
                        {selectedEvent?.numberOfSlots ?
                          <li>
                              <b>Number Of Slots</b>
                              <p>{selectedEvent?.numberOfSlots}</p>
                              </li>
                           : null}

                        {selectedEvent?.qty ?
                          <li>
                              <b>Number Of Slots</b>
                              <p>{selectedEvent?.qty ? selectedEvent?.qty : "0"}</p>
                           </li>
                           : null}
                     {/* </li> */}
                     <li>
                        <b>Slots Start Time</b>
                        <p>{moment(selectedEvent?.slotStartTime, 'HH:mm:ss').format('h:mm A')
                        }</p>
                     </li>
                     {selectedEvent?.type === 'Purchased' ?
                        <div className='redeemcoupan-box'>
                           {selectedEvent?.allRedeemed === true ? (
                              <>
                                 <b>Attention:</b> <span> Slots for Today Are Fully Redeemed</span>
                              </>
                           ) : selectedEvent?.allExpired === true ? (
                              <>
                                 <b>Attention:</b><span> Oops! It looks like the coupon purchase time has closed.</span>
                              </>
                           ) : (
                              <>
                                 <p>Coupon ID</p>
                                 <input
                                    className="redeem-coupan-id-field"
                                    type="text"
                                    id="redeem-value-id"
                                    value={redeemValue}
                                    onChange={(e) => {
                                       const inputValue = e.target.value;
                                       // Only allow alphanumeric characters and ensure the length is 9
                                       if (/^[a-zA-Z0-9]{0,9}$/.test(inputValue)) {
                                         setRedeemValue(inputValue);
                                         setIsInputEmpty(inputValue.length === 0);
                                         setIsInputInvalid(inputValue.length !== 9);
                                       }
                                     }}
                                    pattern="[a-zA-Z0-9]{9}"
                                    placeholder='Enter Coupon ID'
                                 />
                                 <br />
                                 {isInputEmpty && <span className="form-error">This field is required.</span>}
                                 {isInputInvalid && <span className="form-error">Invalid Coupon ID.</span>}
                              </>
                           )}

                        </div>
                        : ''}
                  </ul>

               </div>

               {selectedEvent?.type === 'Purchased' ?
                  selectedEvent?.allRedeemed === true ? (
                     ''
                  ) : selectedEvent?.allExpired === true ? (
                     ''
                  ) : (
                     <>
                        <div className="modal-foot mt-2 text-center">
                           <button className="btn btn-secondry min-w ms-2" onClick={handleRedeem}>
                              Redeem
                           </button>
                        </div>
                     </>
                  )
                  : null}
            </ModalBody>
         </Modal>


    </div>
  )
}
