import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DashBoardRoutes from './DashBoardRoutes'
import AuthRoutes from './AuthRoutes'
import DashBoardPaymentTerms from './DashBoardPaymentTerms';

export default function Routes() {
    const [acceptTerms, setAcceptTerms] = useState(false);
    const auth = useSelector((state)=> state.user &&  state.user.token && 
    state.user.token !== null && state.user.token !==  undefined 
    && state.user.token !== "" ? state.user.token : null);

    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state?.user);
  
    useEffect(() => {
      setTimeout(() => {
        if (user && user?.data && user?.data?.paymentModeType) {
          setAcceptTerms(user?.data?.acceptTerms);
        }
        setLoading(false);
      }, 1000);
    }, [user]);
  
    if (loading) { return  }

    return (
        auth ?  
        !acceptTerms? <DashBoardPaymentTerms />:
         <DashBoardRoutes /> : <AuthRoutes />                        
    )
}
