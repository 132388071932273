import { Navigate, useRoutes } from 'react-router-dom';

// layouts../layouts/dashboard

import AuthLayout from '../Layout/AuthLayout';
import Login from '../Screens/Auth/Login';
import ForgotPassword from '../Screens/Auth/ForgotPassword';
import Signup from '../Screens/Auth/Signup';
import OtpVerify from '../Screens/Auth/OtpVerify';
import ResetPassword from '../Screens/Auth/ResetPassword';
import PhoneVerify from '../Screens/Auth/PhoneVerify';
import EmailVerify from '../Screens/Auth/EmailVerify';
import TermsAndConditions from '../Screens/Common/TermsAndConditions';
import LogoOnlyLayout from '../Layout/LogoOnlyLayout';
import RegistrationAgreement from '../Screens/Common/RegistrationAgreement';
import Support from '../Screens/Main/Support';
import SharedCoupon from '../Screens/Main/SharedCoupon';
import PrivacyPolicy from '../Screens/Common/PrivacyPolicy';
import MerchantTermsAndConditions from '../Screens/Common/MerchantTermsAndConditions';
import UserPrivacyPolicy from '../Screens/Common/UserPrivacyPolicy';
import DeleteAccountPolicy from '../Screens/Common/DeleteAccountPolicy';

// ----------------------------------------------------------------------

export default function AuthRoutes() {
  return useRoutes([
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        { path: 'login', element:<Login />},                       
        { path: 'signup', element:<Signup />},                       
        { path: 'forgot-password', element:<ForgotPassword />},
        { path: 'verify', element:<OtpVerify />},
        { path: 'phone-verify', element:<PhoneVerify />},
        { path: 'email-verify', element:<EmailVerify />},
        { path: 'reset-password', element:<ResetPassword />},
        { path: '/auth', element: <Navigate to="/auth/login" /> },
        { path: '*', element: <Navigate to="/auth/login" /> },
      ]
    },
    {
      path: 'common',
      element:<LogoOnlyLayout />,
      children:[
        { path: 'terms', element:<TermsAndConditions />},
        { path: 'PrivacyPolicy', element:<UserPrivacyPolicy />},
        { path: 'termsandconditions', element:<MerchantTermsAndConditions />},
        { path: 'privacy-policy', element:<PrivacyPolicy />},
        { path: 'delete-account-policy', element:<DeleteAccountPolicy />},
        { path: 'reg-agreement', element:<RegistrationAgreement />},
        { path: 'support', element:<Support />},
        { path: 'shared-coupon/:id?', element: <SharedCoupon />}
      ]
    },
    { path: '/', element: <Navigate to="/auth/login" replace /> },
    { path: '*', element: <Navigate to="/auth/login" replace /> },    
  ]);
}
