import React, { useEffect ,useState } from 'react'
import logo from '../../Assets/images/logo.png'
import { Link } from 'react-router-dom'
import { getSocialLinks } from '../../Services/AuthServices';


export default function Footer() {
  const [data,setData ] = useState();
 
  useEffect(() => {
    getSocialLinks()
      .then((res) => {
        if (res.status === 200) {
          const resData = res?.data?.data;
          setData(resData);
        }
      })
      .catch((err) => {
      });
  }, []);

  return (
    <footer>
      <div className="footer-box">
        <div className="container">
          <div className="row">
            <div className="col-md-4 footer-content">
              <div className="footer-top-left">
                <figure className="mb-4"><Link to="/merchant/dashboard"><img src={logo} alt='logo' /></Link></figure>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud  </p> */}
                <ul className="footer-link">
                  <li><Link to="/common/registration-agreement" target="_blank">Registration Agreement</Link></li>
                  <li><Link to="/common/privacypolicy" target="_blank">Privacy Policy</Link></li>
                  <li><Link to="/common/termsandconditions" target="_blank">Terms and Conditions</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 ms-auto footer-content ps-5">
              <h3>Navigation</h3>
              <ul className="footer-link">
                <li><Link to="/merchant/dashboard">Dashboard</Link></li>
                <li><Link to="/merchant/my-coupon/published-coupon/list">My Coupons</Link></li>
                <li><Link to="/merchant/business-config/detail">Business Configuration</Link></li>
                <li><Link to="/merchant/settings">Settings</Link></li>
                <li><Link to="/merchant/support">Support</Link></li>
              </ul>
            </div>
            <div className="col-md-3 footer-content">
              <h3>Social Media</h3>
              <ul className="Social-link d-flex">
                <li>
                  <Link to={data?.twitterLink} className='mb-2' target="_blank">
                    <i className="ri-twitter-fill"></i>
                  </Link>
                </li>
                <li>
                  <Link to={data?.fbLink} target="_blank">
                    <i className="ri-facebook-fill"></i>
                  </Link>
                </li>
                <li>
                  <Link to={data?.googleLink} target="_blank">
                    <i className="ri-google-fill"></i>
                  </Link>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
