import { Navigate, useRoutes } from 'react-router-dom';
import PaymentTermsAndConditions from '../Screens/Common/PaymentTermsAndConditions';
import LogoOnlyLayout from '../Layout/LogoOnlyLayout';
import Pay from '../Screens/Common/Pay';
import MerchantTermsAndConditions from '../Screens/Common/MerchantTermsAndConditions';
import UserPrivacyPolicy from '../Screens/Common/UserPrivacyPolicy';
// ----------------------------------------------------------------------

export default function DashBoardPaymentTerms() {
  return useRoutes([
    {
      path: '/merchant',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'payment-terms-Conditions', element:<PaymentTermsAndConditions />},        
        { path: 'subscription-payment', element:<Pay />},  
        { path: '/merchant', element: <Navigate to="/merchant/payment-terms-Conditions" replace /> },
        { path: 'termsandconditions', element:<MerchantTermsAndConditions />},
        { path: 'PrivacyPolicy', element:<UserPrivacyPolicy />},
        { path: '*', element: <Navigate to="/merchant/payment-terms-Conditions" replace /> },      
      ]
    },
    { path: '*', element: <Navigate to="/merchant/payment-terms-Conditions" replace /> }
  ]);
}