import AxiosInterceptor from "../Utils/AxiosInterceptor";

const PREFIX = "access/";

export const getUserTerms = async () => {
    return await AxiosInterceptor().get(PREFIX + `getUserTerms`);
};

export const getTerms = async () => {
    return await AxiosInterceptor().get(PREFIX + `getTerms`);
};

export const getPrivacyPolicy = async () => {
    return await AxiosInterceptor().get(PREFIX + `getPrivacyPolicy`);
};

export const getUserPrivacyPolicy = async () => {
    return await AxiosInterceptor().get(PREFIX + `getUserPrivacyPolicy`);
};


export const getRegisterAgreement = async () => {
    return await AxiosInterceptor().get(PREFIX + `getRegisterAgreement`);
};

export const getPlanTerms = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `getPlanTerms` ,payload);
};

export const subscriptionPay = async (payload) => {
    return await AxiosInterceptor().post(PREFIX + `subscriptionPay` ,payload);
};

export const acceptTerms = async () => {
    return await AxiosInterceptor().get(PREFIX + `acceptTerms`);
};

export const getUserDeleteAccountPolicy = async () => {
    return await AxiosInterceptor().get(PREFIX + `getUserDeleteAccountPolicy`);
};