import React from 'react'
import CouponDetail from './CouponDetail';

export default function SharedCoupon() {
    return (
        <>
        <CouponDetail />
        </>
    )
}
