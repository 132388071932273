import React, { useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { emailVerifyService, resendEmailOtp } from '../../Services/AuthServices';
import * as Yup from 'yup';
import { tostE, tostS } from '../../Utils/Toast';
import OTPInput from 'react-otp-input';
import logo from './../../Assets/images/logo.png'
export default function OtpVerify() {
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.user && state.user.data ? state.user.data : null);
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process

    const otpValidation = Yup.object().shape({
        otp: Yup.string().required('Please enter otp').min(4, "Enter a valid OTP")
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: ""
        },
        validationSchema: otpValidation,
        onSubmit: async (values) => {
            setStartTime(new Date()); // Record start time
            setLoading(true); // Show loader before making API call
            const payload = {
                email: currentUser?.email,
                emailOtp: values.otp
            }
            emailVerifyService(payload).then((res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                        setStartTime(null); // Reset start time
                        navigate("/auth/reset-password", {replace:true});
                    }, 2000);
                }
            }).catch((err) => {
                if(err?.response?.data){
                    setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                        setStartTime(null); // Reset start time
                        tostE(err?.response?.data?.message);
                    }, 1000);
                    tostE(err?.response?.data?.message);
                }
            }).finally((err1) => {
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                    tostE(err1?.response?.data?.message);
                }, 2000);
            });    
        }
    })

    const { errors, values, touched, handleSubmit, setValues } = formik;

    const handleResend = () => {
        setStartTime(new Date()); // Record start time
        setLoading(true); // Show loader before making API call
        const payload = {
            email: currentUser?.email,
        }

        resendEmailOtp(payload).then((res) => {
            if (res.status === 200) {
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                    tostS(res?.data?.message)
                }, 2000);
            }
        }).catch((err) => {
            if(err?.response?.data){
                tostE(err?.response?.message);
            }
        }).finally((err1) => {
          setTimeout(() => {
              setLoading(false); // Hide loader after API call completes
              setStartTime(null); // Reset start time
              tostE(err1?.response?.data?.message);
          }, 2000);
      });     
    }

    return (
        <>
            {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}               
            <div className="form-head mb-4 text-center">
                <h4>OTP Verification</h4>
                <p>Enter the OTP sent to your email</p>
            </div>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <div className="form-body">
                        <div className="form-group">
                            <OTPInput
                                renderSeparator={false}
                                containerStyle={"otp-form d-flex mt-3"}
                                inputStyle={{ width: "100%" }}
                                placeholder='----'
                                numInputs={4}
                                value={values.otp}
                                inputType='number'
                                onChange={(otp) => { setValues({ ...values, otp: otp }) }}
                                renderInput={(props) => <input placeholder="-" class={"form-control"} {...props} />}
                            />
                            {errors.otp && touched.otp ? <span className="form-error">{errors.otp}</span> : null}
                        </div>
                        <div className="form-btn-box mt-4">
                            <button type='submit' className="btn btn-secondry w-100">Verify </button>
                        </div>
                        <div className="recive-otp form-bottom-text text-center mt-3">
                            <span>Didn’t receive OTP? <button type="button" onClick={()=>{handleResend()}} className="form-link">Resend</button></span>
                        </div>
                    </div>
                </Form>
            </FormikProvider>
            <div className="form-bottom form-bottom-text">
                <Link
                    to={"/auth/login"}
                    className="form-link"
                >
                    Back to Login
                </Link>
            </div>
        </>
    )
}
