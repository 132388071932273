import React, { useEffect, useState } from 'react';
import {getPlanTerms ,acceptTerms } from '../../Services/CommonServices';
import HtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { cleanAllData, updateAcceptTerms } from '../../Actions/ActionCreator';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../Assets/images/logo.png'
import { tostE, tostS } from '../../Utils/Toast';
import { rejectTerms } from '../../Services/AuthServices';
import { Modal, ModalBody } from 'react-bootstrap';

export default function PaymentTermsAndConditions() {
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process 
    const dispatch = useDispatch();
    const [data, setData] = useState({});    
    const user = useSelector(state => state.user);
    const navigation = useNavigate();
    const [show, setShow] = useState(false);

    useEffect(() => {
        let  paymentModeType;
        if (user && user.data && user.data.paymentModeType) {
            paymentModeType = user.data.paymentModeType
        }
        const payload={};
        let slug;
        if (paymentModeType === "revshare")
        payload.slug = "merchant-revshare-terms";
        else if (paymentModeType === "subscription")
        payload.slug = "merchant-subscription-terms";
        else if (paymentModeType === "hybrid")
        payload.slug = "merchant-hybrid-terms";
        else
        payload.slug = "";

        setStartTime(new Date());
        setLoading(true); 
        getPlanTerms(payload).then((res) => {
            if (res.status === 200) {
                setTimeout(() => {
                    setData(res?.data?.data);
                    setLoading(false);
                },800);
                
            }
        }).catch((err) => {
            setLoading(false);
        });

    }, []);


    const handleLogout = () => {
        setStartTime(new Date());
        setLoading(true); 
        rejectTerms().then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
                
                dispatch(cleanAllData());
                setLoading(false);
            },800);
            
          }
        })
        .catch((err) => {
            setLoading(false);
        });          
    }

    const handleClose = () => {
        setShow(false);
      }

    let  AccptpaymentModeType;
    if (user && user.data && user.data.paymentModeType) {
        AccptpaymentModeType = user.data.paymentModeType
    }

   const acceptTermsButton = async () => {
        if("revshare" === AccptpaymentModeType){
            setStartTime(new Date());
            setLoading(true); 
            acceptTerms().then((res)=>{
                if(res.status==200){
                    setTimeout(() => {
                        setLoading(false);
                        dispatch(updateAcceptTerms(true));
                    },500);
                }
            }).catch((err)=>{ 
                tostE(err?.data?.message);
            })    
        }
        if("subscription" === AccptpaymentModeType || "hybrid" === AccptpaymentModeType){
            setStartTime(new Date());
            setLoading(true); 
            setTimeout(() => {
                setLoading(false);
                navigation("/merchant/subscription-payment")
            },800);
            
        }
    };

    return (
        <>
    <div className="wraper-inner">
       <span className='login-support-button logout-payment-button'><span onClick={() => { setShow(true) }}>Logout</span></span>
        <section className="form-page">
        </section>
    </div>
       

        <div className='common-wrapper'>
        {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}
            <div className='card common-content-card box-shadow'>

                {HtmlParser(data?.description)}
                <div className="modal-foot mt-2 text-center">
                  <button className="btn btn-primary min-sm-w ms-2 reject-custom-css" onClick={acceptTermsButton}>
                  Accept
                  </button>
                  <button className="btn btn-secondry min-sm-w ms-2 reject-custom-css" onClick={() => { handleLogout() }}>
                  Reject
                  </button>
               </div>
            </div>
        </div>
        <Modal className='modal fade form-modal' show={show} backdrop="static" onHide={handleClose}>
        <ModalBody>
          <div className="model-head logout-modal">
            <figure><i className="fas fa-sign-out-alt"></i></figure>
          </div>
          <figcaption>
            <h4>Are you sure you want to logout</h4>
            {/* <p>Thank you for submitting the request. It’s under review and we will get back to you soon.</p> */}
            <div className='logout-modal-button'>
              <button onClick={() => { handleLogout() }} className="btn btn-secondry">Logout</button>
              <button onClick={() => { handleClose() }} className="btn btn-secondry">Cancel</button>
            </div>
          </figcaption>
        </ModalBody>
      </Modal>
        </>
    );
}
