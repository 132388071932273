import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import HeroImg from '../../../Assets/images/support-img.png';
import { MOBILE_REGEX, NOSPACE_REGEX, NOSPACE_REGEX_EMAIL } from '../../../Utils/Constants';
import { sendInquiry , sendInquiryBeforeLogin } from '../../../Services/SupportServices';
import { tostE, tostS } from '../../../Utils/Toast';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../../Assets/images/logo.png'

export default function Support() {
   const [loading, setLoading] = useState(false); // State for loader
   const [setStartTime] = useState(null); // Start time of update process

   const currentUser = useSelector(state => state.user.data)
   const validationSchema = Yup.object().shape({
      name: Yup.string()
         .required('Name is required')
         .matches(NOSPACE_REGEX, "Please enter a valid name")
         .min(2, "Please enter at least 2 characters"),
      email: Yup.string()
         .trim()
         .email("Please enter a valid email")
         .required('Email is required')
         .matches(NOSPACE_REGEX_EMAIL, "Please enter a valid email"),
      phoneNumber: Yup.string()
         .required('Phone is required')
         .matches(MOBILE_REGEX, "Please Enter valid number"),
      couponId: Yup.string()
         .matches(/[^\s*].*[^\s*]/g, "Coupon ID not start with a blank space")
         .matches(/^[a-zA-Z0-9]{0,9}$/, "Invalid Coupon ID.")
         .min(9, "Coupon ID must be 9 digits only.")
         .max(9, "Coupon ID must be 9 digits only."),         
      description: Yup.string()
         .required('Message is required')
         .matches(NOSPACE_REGEX, "Please Enter a valid message")
         .min(2, "Please enter at least 2 characters")
         .max(500, "Only 500 characters allow"),
   })

   const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
         name: currentUser?.businessName ?? "",
         email: currentUser?.email ?? "",
         phoneNumber: currentUser?.phoneNumber ?? "",
         couponId: "",
         description: ""
      },
      validationSchema: validationSchema,
      onSubmit: async (values, { resetForm }) => {
         setStartTime(new Date()); // Record start time
         setLoading(true); // Show loader before making API call
         values.email = values.email.replace(/\s/g, "");
         const payload = {
            name: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            couponId: values.couponId,
            description: values.description
         }
   
         if (!currentUser?.id) {
               sendInquiryBeforeLogin(payload).then((res) => {
                  if (res.status == 200) {
                     setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                        setStartTime(null); // Reset start time
                       tostS(res.data.message);
                       resetForm();
                    }, 500);
                  }
               }).catch((err) => {
                  // console.log(err);
                  if (err?.response?.data) {
                     setTimeout(() => {
                        setLoading(false); // Hide loader after API call completes
                        setStartTime(null); // Reset start time
                        tostE(err?.response?.data?.message);
                    }, 500);
                    
                  }
               }).finally(() => {
                  setTimeout(() => {
                      setLoading(false); // Hide loader after API call completes
                      setStartTime(null); // Reset start time
                  }, 500);
              });

         } else {
            // User is logged in, sending inquiry with token
            sendInquiry(payload).then((res) => {
               if (res.status == 200) {
                  setTimeout(() => {
                     setLoading(false); // Hide loader after API call completes
                     setStartTime(null); // Reset start time
                    tostS(res.data.message);
                    resetForm();
                 }, 2000);
               }
            }).catch((err) => {
               // console.log(err);
               if (err?.response?.data) {
                  setTimeout(() => {
                     setLoading(false); // Hide loader after API call completes
                     setStartTime(null); // Reset start time
                     tostE(err?.response?.data?.message);
                 }, 2000);
                 
               }
            }).finally(() => {
               // Simulate 2 seconds delay
               setTimeout(() => {
                   setLoading(false); // Hide loader after API call completes
                   setStartTime(null); // Reset start time
               }, 2000);
           });
         }
      }
   });
  
   
   const { errors, values, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

   return (
      <div className="support-sec section-space">
          {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}
         <div className="container">
            <div className="coupan-sec-top-head mb-4">
               <div className="page-title ">
                  <h1>Support</h1>
               </div>
            </div>
            <div className="support-info-main mt-5">
               <div className="row align-items-center">
                  <div className="col-md-4">
                     <figure><img src={HeroImg} alt='image' /></figure>
                  </div>
                  <div className="col-md-8 ps-4">
                     <div className="section-title mb-4">
                        <h2>Connect with Us and Ask your Queries</h2>
                        <p>Send us your queries and we will revert back to you</p>
                     </div>
                     <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                           <div className="row">
                              <div className="col-md-6 mb-3">
                                 <label className="form-label">Business Name</label>
                                 <input
                                    type="text"
                                    name="name"
                                    placeholder="Enter business name"
                                    className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Business Name"
                                    readOnly={currentUser?.id ? true : false}
                                 />
                                 {errors.name && touched.name ? <span className="form-error">{errors.name}</span> : null}
                              </div>
                              <div className="col-md-6 mb-3">
                                 <label className="form-label">Email</label>
                                 <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    className={"form-control" + (errors.email && touched.email ? " is-invalid" : "")}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Email"
                                    readOnly={currentUser?.id ? true : false}
                                 />
                                 {errors.email && touched.email ? <span className="form-error">{errors.email}</span> : null}
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">Phone Number</label>
                                <input 
                                    name="phoneNumber"
                                    type="number" 
                                    placeholder="Enter phone number" 
                                    className={"form-control" + (errors.phoneNumber && touched.phoneNumber ? " is-invalid" : "")} 
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Phone Number"
                                    readOnly={currentUser?.id ? true : false}
                                />
                                {errors.phoneNumber && touched.phoneNumber ? <span className="form-error">{errors.phoneNumber}</span> : null}
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">Coupon ID (optional)</label>
                                <input 
                                    name="couponId"
                                    type="text" 
                                    placeholder="Enter Coupon ID" 
                                    className={"form-control" + (errors.couponId && touched.couponId ? " is-invalid" : "")} 
                                    value={values.couponId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Coupon ID"
                                    maxLength={9}
                                />
                                {errors.couponId && touched.couponId ? <span className="form-error">{errors.couponId}</span> : null}
                              </div>
                              <div className="col-md-12 mb-3">
                                 <label className="form-label">Message</label>
                                 <textarea
                                    type="text"
                                    name="description"
                                    placeholder="Enter message"
                                    className={"form-control textarea-box" + (errors.description && touched.description ? " is-invalid" : "")}
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    title="Message"
                                 />
                                 {errors.description && touched.description ? <span className="form-error">{errors.description}</span> : null}
                              </div>
                              <div className="col-md-12 mt-3 btn-group-box responsive-btn">
                                 <button type="submit" className="btn btn-secondry max-w">Send</button> 
                                 {!currentUser?.id && (                                
                                     <Link to="/auth/login" className="btn btn-secondary max-w login-support-back">Back</Link>
                                 )}
                              </div>
                           </div>
                        </Form>
                     </FormikProvider>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
