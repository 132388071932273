import React, { useEffect, useState ,useRef} from 'react'
import { Avatar } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom'
import { tostE, tostS } from '../../../Utils/Toast';
import { getCategories,addCouponTemplate } from '../../../Services/CoupanServices';
import Select from 'react-select';
import logo from '../../../Assets/images/logo.png'
import ImageCompressor from 'image-compressor.js';

export default function AddNewCoupon() {
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process
    const fileInputRef = useRef(null);
    const [loadingImage, setLoadingImage] = useState(false); // State for loader
  
    
    useEffect(() => {
        getCategories().then((res) => {
            if (res.status === 200) {
                const resData = res.data.data;
                const modifiedData = resData.map((item) => ({ value: item.categoryId, label: item.name }));
                setCategoryList(modifiedData)
            }
        }).catch((err) => {
            tostE(err?.response?.data?.message);
        })
    }, [])

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required')
            .max(150, "Title should be less than 150 characters")
            .matches(/^[^\s]+(?:\s.*)?$/, 'Title should not start with a blank space'),
        description: Yup.string()
            .required('Description is required')
            .max(499, 'Description must be at most 500 characters'),
        duration: Yup.string()
            .required('Duration is required')
            .min(1, 'Duration must be greater than 0')
            .matches(/^[^\s]+(?:\s.*)?$/, 'Duration should not start with a blank space'),
        durationText: Yup.string()
            .required('Duration is required')
            .matches(/^[^\s]+(?:\s.*)?$/, 'Duration should not start with a blank space'),
        price: Yup.number()
            .required('Please enter the price')
            .positive('Price must be greater than 0')
            .max(999999.99, 'Amount must be no more than $999,999.99')
            .typeError('Please enter a valid price'),
        disPercentage: Yup.number()
            .min(1, 'Discount percentage must be greater than 0')
            .required('Please enter the discount percentage')
            .typeError('Please enter a valid discount percentage'),  
        couponImageURL: Yup.string()
            .required('Coupon Image is required'),
        galleryImagesURL: Yup.array()
            .max(3, "Select up to 3 images"),
        categoryId: Yup.string()
            .required('Category is required')

    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: "",
            categoryId: "",
            description: "",
            duration: "30", // Default duration set to 30 minutes
            durationText: "Minutes", // Default radio button value set to "Minutes"
            price: "",
            disPercentage: "",
            discountedPrice : 0,
            imagePath: "merchant/coupon",
            couponImage: "",
            couponImageURL: "",
            galleryImages: [],
            galleryImagesURL: [],
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {

            if (values.durationText === "Hours" && (values.duration === "" || values.duration < 1 || values.duration > 24)) {
                setFieldError("duration", "Duration should be between 1 to 24 hours");
                return;
            }

            if (values.durationText === "Minutes" && (values.duration === "" || values.duration < 1 || values.duration > 1440)) {
                setFieldError("duration", "Duration should be between 1 to 1440 minutes");
                return;
            }

            // if (values.durationText === "Minutes") {
            //     if (duration % 1 !== 0) {
            //         setFieldError("duration", "Duration in minutes should be a whole number");
            //         return;
            //     }
            //     if (duration < 1 || duration > 1440) {
            //         setFieldError("duration", "Duration should be between 1 to 1440 minutes");
            //         return;
            //     }
            // }
            
            if(values.duration<1){
                setFieldError("duration", "Duration should be greater than zero")
                return;
            }

            let duration = values.duration;

            if(values.durationText == "Hours"){
                duration*=60;
            }

            setStartTime(new Date()); // Record start time
            setLoading(true); // Show loader before making API call            
            let formData = new FormData();
            formData.append("title", values.title);
            formData.append("categoryId", values.categoryId);
            formData.append("description", values.description);
            formData.append("duration", duration);
            formData.append("durationText", values.durationText);
            formData.append("price", values.price);
            formData.append("disPercentage", values.disPercentage);
            formData.append("discountedPrice", values.discountedPrice);
            formData.append("imagePath", values.imagePath);
            formData.append("couponImage", values.couponImage);
            values.galleryImages.map(img => {
                formData.append("galleryImages", img);
            })

            addCouponTemplate(formData).then((res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                       setLoading(false); // Hide loader after API call completes
                       setStartTime(null); // Reset start time
                       tostS(res.data.message);
                       navigate("/merchant/my-coupon/coupon-template/list")
                    }, 500);

                }
            }).catch((err) => {
                if (err?.response?.data) {
                    tostE(err?.response?.data?.message);
                }
            }).finally(() => {
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                }, 500);
            });
        }
    });


    const { errors, values, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setValues, setFieldError } = formik;

    const getDiscountedPrice = (price, discount) => {
        return (price - (price * (discount/100))).toFixed(2)        
    }

    const handlePriceChange = (e) => {
        let value = e.target.value;
        
        value = value.replace(/[^\d.]/g, '');

        const dotIndex = value.indexOf('.');
        if (dotIndex !== -1) {
            value = value.substring(0, dotIndex + 1) + value.substring(dotIndex + 1).replace(/\./g, '');
        }
    
        const decimalParts = value.split('.');
        if (decimalParts[1] && decimalParts[1].length > 2) {
            value = `${decimalParts[0]}.${decimalParts[1].slice(0, 2)}`;
        }
        if (value.length > 0 && value.charAt(0) === '0') {
        }
    

        setFieldValue("price", value);
            
        if (!isNaN(value)) {
            setFieldValue("price", value);
            if(values.disPercentage){
                setFieldValue("discountedPrice", getDiscountedPrice(value, values.disPercentage));
            }
        }
    }

    const handleDurationChange = (e) => {
        const value = e.target.value;
    
        if (value === "") {
            setFieldValue("duration", "");
            setFieldError("duration", "Duration is required");
            return;
        }
    
        let re;
        if(values.durationText === "Hours"){
             re = /^([0-9]*[.,]?[0-9]?)$/;  // Allows decimal numbers
        }else if(values.durationText === "Minutes"){
             re = /^[0-9]{0,4}$/;   
        }

        if (re.test(value)) {
            if (values.durationText === "Hours" && value <= 24) {
                setFieldValue("duration", value);
                setFieldError("duration", value)
            } else if (values.durationText === "Minutes" && value <= 1440) {
                setFieldValue("duration", value);
              
            } else {
                setFieldError("duration", `Duration should be between 1 to ${values.durationText === "Hours" ? 24 : 1440}`);
            }
        }
    }    

    const handleDiscountChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value <= 100) {
            setFieldValue("disPercentage", value);
            if(values.price){
                setFieldValue("discountedPrice", getDiscountedPrice(values.price, value));
            }
        }
    }



    const handleImageCompression = async (file) => {
        try {
            const compressor = new ImageCompressor();
            const compressedBlob = await compressor.compress(file, {
                maxWidth: 300,
                maxHeight: 300,
                quality: 0.8,
                mimeType: 'image/jpeg',
            });
    
            const compressedFile = new File([compressedBlob], file.name, {
                type: compressedBlob.type,
                lastModified: Date.now(),
            });
    
            return compressedFile;
        } catch (error) {
            throw error;
        }
    };

    const handleUploadCouponImage = async (event) => {
        const file = event.target.files[0];
        const imageTypeRegex = /^image\//;
    
        if (!file) return;
    
        if (
            event.target.files[0].type === "image/png" ||
            event.target.files[0].type === "image/jpg" ||
            event.target.files[0].type === "image/jpeg"
          ){
        const img = new Image();
        setLoading(true);
        const compressedFile = await handleImageCompression(file);
        setLoading(false);
        img.onload = () => {
           
            setValues({
                ...values,
                couponImage: compressedFile,
                couponImageURL: URL.createObjectURL(compressedFile)
            });
        };
    
        img.onerror = () => {
            tostE("The uploaded image is corrupted or invalid. Please upload a valid image.");
            event.target.value = null;
        };
    
        img.src = URL.createObjectURL(compressedFile);
        } else {
            tostE("Please upload a file with .png, .jpg, or .jpeg format.");
        }
    };

    const handleUploadGalleryImage = (event) => {
        const filesObject = event.target.files;
        const files = Object.keys(filesObject).map((key) => filesObject[key]);
    
        if (files.length + values.galleryImages.length > 3) {
            tostE("Select up to 3 images");
            return;
        }

        const validFiles = [];


        if (
            event.target.files[0].type === "image/png" ||
            event.target.files[0].type === "image/jpg" ||
            event.target.files[0].type === "image/jpeg"
          ){

        const promises = files.map( async(file) => {
            setLoadingImage(true);
            const compressedFile = await handleImageCompression(file);
            setLoadingImage(false)
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    const exists = values.galleryImages.some((existingImage) =>
                        existingImage.name === file.name && existingImage.size === compressedFile.size
                    );
                    if (!exists) {
                       
                        validFiles.push(compressedFile);
                        resolve(URL.createObjectURL(compressedFile));
                    } else {
                      // reject(`Image '${file.name}' is already uploaded.`);
                    }
                };

                img.onerror = () => {
                    
                    reject(`Image '${file.name}' is corrupted or invalid. Please upload only valid images.`);
               };
    
                img.src = URL.createObjectURL(compressedFile);
            });
        });
       
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
      
        Promise.all(promises).then((urls) => {
            const filteredUrls = urls.filter((url) => url !== null);
            const updatedGalleryImages = [...values.galleryImages, ...validFiles.slice(0, 10 - values.galleryImages.length)];
            const updatedGalleryImagesURL = [...values.galleryImagesURL, ...filteredUrls];
            setValues({
                ...values,
                galleryImages: updatedGalleryImages,
                galleryImagesURL: updatedGalleryImagesURL
            });
        })
        .catch((error) => {
            tostE(error);
        });
    } else {
        tostE("Please upload a file with .png, .jpg, or .jpeg format.");
    }
    };

    const handleRemoveImage = (idx) => {
        const currentImages = [...values.galleryImages];
        const currentImagesURL = [...values.galleryImagesURL];
    
        currentImages.splice(idx, 1);
        currentImagesURL.splice(idx, 1);
    
        setValues({
            ...values,
            galleryImages: currentImages,
            galleryImagesURL: currentImagesURL
        });
    
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }

    const handleRadioChange = (e) => {
        const { value } = e.target;
        setFieldValue("durationText", value);
        if (value === "Hours") {
            setFieldValue("duration", "");
        } else {
            setFieldValue("duration", "30");
        }
    };
     

    const handleDescriptionChange = (e) => {
        const maxLength = 500; // Maximum allowed characters
        let value = e.target.value;
    
        const startsWithSpace = value.startsWith(' ');
    
        const descriptionError = startsWithSpace ? "Description should not start with a blank space" : "";
    
        if (value.length > maxLength) {
            value = value.substring(0, maxLength);
        }
    
        setValues({
            ...values,
            description: value,
            descriptionError: descriptionError
        });
    };

    return (
        <div className="add-new-coupon-sec section-space">
            {loading && (
                <div className='preload'>
                <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}
            <div className="container">
                <div className="coupan-sec-top-head mb-4">
                    <div className="page-title ">
                        <h1>Add New Coupon Template</h1>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/merchant/my-coupon/published-coupon/list">My Coupons</Link></li>
                            <li className="breadcrumb-item"><Link to="/merchant/my-coupon/coupon-template/list">Coupon Templates </Link></li>
                            <li className="breadcrumb-item active" aria-current="page"> Add New Coupon Template</li>
                        </ol>
                    </nav>
                </div>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <div className="add-new-coupon-info-main mt-5">
                            <div className="row">
                                <div className="col-md-8 add-new-coupon-box m-auto">
                                    <div className="box-shadow ">
                                        <div className="upload-photo m-auto">
                                            <div className="avatar-upload">
                                                <div className="avatar-edit">
                                                    <input onChange={(e) => { handleUploadCouponImage(e) }} type="file" id="imageUpload" accept=".png, .jpg, .jpeg" />
                                                    <label for="imageUpload" className="img-upload-icon"><i className="ri-add-fill"></i>
                                                    </label>
                                                </div>
                                                <Avatar className="avatar-preview" alt="Coupon Image" src={values.couponImageURL} />
                                            </div>
                                            {errors.couponImageURL && touched.couponImageURL ?
                                                <div className='text-center '>
                                                    <span className="form-error">{errors.couponImageURL}</span>
                                                </div>
                                            : null}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label">Category</label>
                                                <Select
                                                    className={"custom-select" + (errors.categoryId && touched.categoryId ? " is-invalid" : "")}
                                                    name="category"
                                                    id="category"
                                                    title="Category"
                                                    placeholder={"Select Category"}
                                                    options={categoryList}
                                                    onBlur={handleBlur}
                                                    onChange={(value) => {
                                                        setFieldValue("categoryId", value?.value);
                                                    }}
                                                    value={categoryList.find(item => item.value == values.categoryId)}
                                                />
                                                {errors.categoryId && touched.categoryId ? <span className="form-error">{errors.categoryId}</span> : null}
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label">Title</label>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter title"
                                                    className={"form-control" + (errors.title && touched.title ? " is-invalid" : "")}
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    title="Title"
                                                />
                                                {errors.title && touched.title ? <span className="form-error">{errors.title}</span> : null}
                                            </div>
                                            <div className="col-md-12 mb-2">
                                                <label className="form-label">Description</label>
                                                <textarea
                                                    type="text"
                                                    name="description"
                                                    placeholder="Enter description"
                                                    className={"form-control textarea-box " + (errors.description && touched.description ? " is-invalid" : "")}
                                                    value={values.description}
                                                    onChange={handleDescriptionChange}
                                                    onBlur={handleBlur}
                                                    title="Description"
                                                />
                                                {values.descriptionError && <span className="form-error">{values.descriptionError}</span>}                                  
                                                {errors.description && touched.description ? <span className="form-error">{errors.description}</span> : null}

                                            </div>
                                            
                                            <div className="col-md-12 mb-6 serviice-field-coupan">
                                            <label className="form-label">Service Duration<span className='form-label-helper'> (default 30 minutes) </span></label>
                                            <div className="inline-radio-input">
                                                <div className="form-check form-check-inline">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="radio" 
                                                        name="durationText" 
                                                        id="flexRadioDefault1" 
                                                        value="Hours" 
                                                        checked={values.durationText === "Hours"}
                                                        onChange={handleRadioChange} 
                                                    />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">Hours</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="radio" 
                                                        name="durationText" 
                                                        id="flexRadioDefault2" 
                                                        value="Minutes" 
                                                        checked={values.durationText === "Minutes"}
                                                        onChange={handleRadioChange} 
                                                    />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault2">Minutes</label>
                                                </div>
                                            </div>
                                           
                                                <div className="col-md-6 mb-3 add-coupan-service-duration">
                                                    <input
                                                        type="text"
                                                        name="duration"
                                                        value={values.duration}
                                                        onChange={(e) =>{handleDurationChange(e)}}
                                                        onBlur={handleBlur}
                                                        title="Service Duration"
                                                        placeholder="Enter duration"
                                                        className={"form-control" + (errors.duration && touched.duration ? " is-invalid" : "")}
                                                    />
                                                    {errors.duration && touched.duration ? <span className="form-error">{errors.duration}</span> : null}
                                                </div>
                                            
                                        </div>                              
                                            <div className="col-md-6 mb-3 ">
                                                <label className="form-label">List Price</label>
                                                <input
                                                    type="text"
                                                    name="price"
                                                    value={values.price}
                                                    onChange={(e) => { handlePriceChange(e) }}
                                                    onBlur={handleBlur}
                                                    title="List price"
                                                    placeholder="Enter list price"
                                                    className={"form-control" + (errors.price && touched.price ? " is-invalid" : "")}
                                                />
                                                {errors.price && touched.price ? <span className="form-error">{errors.price}</span> : null}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Coupon Discount Percentage</label>
                                                <input
                                                    type="text"
                                                    name="disPercentage"
                                                    value={values.disPercentage}
                                                    onChange={(e) => { handleDiscountChange(e) }}
                                                    onBlur={handleBlur}
                                                    title="Coupon Discount Percentage"
                                                    placeholder="Enter discount percentage"
                                                    className={"form-control" + (errors.disPercentage && touched.disPercentage ? " is-invalid" : "")}
                                                />
                                                {errors.disPercentage && touched.disPercentage ? <span className="form-error">{errors.disPercentage}</span> : null}
                                                {(values.price && values.disPercentage) && <p>Discounted Coupon Price : <b className='discont-price-add-copan'>${values.discountedPrice}</b></p>}
                                            </div>
                                            <div className="col-md-12 mb-2">
                                                <label className="form-label">Upload Additional Images(Up to 3)</label>
                                                <div className="file-upload-wrapper" data-text="Select your file!">
                                                <input
                                                    ref={fileInputRef} 
                                                    onChange={(e) => { handleUploadGalleryImage(e) }}
                                                    multiple={true}
                                                    name="file-upload-field"
                                                    type="file"
                                                    className="file-upload-field"
                                                    accept="image/*"
                                                    placeholder="File"
                                                />

                                                    <button className="btn-upload add-coupan-btn"><i className="ri-upload-2-line"></i></button>
                                                </div>
                                                {errors.galleryImagesURL && touched.galleryImagesURL ? <span className="form-error">{errors.galleryImagesURL}</span> : null}
                                                <div className="uploaded-img-box">
                                                    <ul className="uploaded-img-list">
                                                        {values.galleryImagesURL.map((image, idx) => (
                                                            <li key={idx}>
                                                                <figure><img src={image} alt='uploaded-img' /></figure>
                                                                <span onClick={() => { handleRemoveImage(idx) }} className="close-img cursor"><i className="ri-close-fill"></i></span>
                                                            </li>
                                                        ))}
                                                        {loadingImage && (
                                                            <li id="loaderImage"></li>
                                                        )}
                                                    </ul>
                                                </div>
                                                <div className="col-md-12 text-center mt-4 btn-group-box">
                                                    <button type='submit' className="btn btn-secondry max-w">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </FormikProvider>
            </div>
        </div>
    )
}
