import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CouponCard from '../../../Components/MyCoupons/CouponCard';
import { getAllCouponTemplates } from '../../../Services/CoupanServices';
import { Pagination, PaginationItem } from '@mui/material';
import logo from '../../../Assets/images/logo.png';

export default function CouponTemplateList() {
 
 
 
  const [couponTemplates, setCouponTemplates] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true); // State for loader
  const [totalRecords, setTotalRecords] = useState(0); // New state to track total records
  const [startTime, setStartTime] = useState(null); // Start time of update process

  useEffect(() => {
    const fetchCouponTemplates = async () => {
      setStartTime(new Date()); // Record start time
      setLoading(true); // Show loader before making API call
      try {
        const response = await getAllCouponTemplates({ pageNumber, pageSize });
        if (response.status === 200) {
          setTimeout(() => {
            setCouponTemplates(response.data.data.result);
            setPageSize(response.data.data.pageSize);
            setTotalRecords(response.data.data.totalRecords); // Set total records
            const calculatedTotalPages = Math.ceil(response.data.data.totalRecords / pageSize); // Calculate total pages based on total records
            setTotalPages(calculatedTotalPages);
            setLoading(false); 
          }, 2000);
        }
      } catch (error) {
        console.error('Error fetching coupon templates:', error);
        setLoading(false); 
      }
    };

    fetchCouponTemplates();
  }, [pageNumber, pageSize]);

  const handlePageChange = (event, value) => {
    if(value < 1 || value > totalPages){
      return
    }
    setLoading(true);
    setPageNumber(value);
  };
  return (
    <>
      {loading && (
        <div className='preload'>
          <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
        </div>
      )}

      <div className="additional-content">
        <Link
          className="add-new-coupon btn btn-secondry"
          to="/merchant/my-coupon/list/add"
        >
          <i className="ri-add-circle-line me-2"></i>  Add New Coupon Template
        </Link>
        <div className="coupan-info-main">
          <div className="row">
            {couponTemplates.map((template, index) => (
              <CouponCard key={index} data={template} />
            ))}
          </div>
          {totalPages > 1 && (
              <div className="text-center pagination-main mt-4">
                <div className="pagination-nav">
                  <Pagination
                    count={totalPages}
                    shape="rounded"
                    className='pagination'
                    showPrevButton={true}
                    showNextButton={true}
                    onChange={handlePageChange}
                    page={pageNumber}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        className={"page-item " + (item.selected ? "active" : "")}
                        disabled={item.page === pageNumber} 
                      />
                    )}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
}
