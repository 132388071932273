import React, { useEffect, useState } from "react";
import CouponCard from "../../../Components/MyCoupons/CouponCard";
import { Pagination, PaginationItem } from "@mui/material";
import logo from "../../../Assets/images/logo.png";
import { getAllCouponPublished } from "../../../Services/CoupanServices";
import imgg from "./../../../Assets/images/coupan-empaty-bg.png";
import { tostE } from "../../../Utils/Toast";

export default function PublishedCouponList() {
  const [couponTemplates, setAllCouponPublished] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true); // State for loader
  const [totalRecords, setTotalRecords] = useState(0); // New state to track total records
  const [startTime, setStartTime] = useState(null); // Start time of update process

  useEffect(() => {
    const fetchCouponTemplates = async () => {
      setStartTime(new Date()); // Record start time
      setLoading(true); // Show loader before making API call
      try {
        let  currentDate = new Date()
        const response = await getAllCouponPublished({ pageNumber, pageSize ,currentDate});
        if (response.status === 200) {
          setTimeout(() => {
            setAllCouponPublished(response.data.data.result);
            setPageSize(response.data.data.pageSize);
            setTotalRecords(response.data.data.totalRecords); // Set total records
            const calculatedTotalPages = Math.ceil(
              response.data.data.totalRecords / pageSize
            ); // Calculate total pages based on total records
            setTotalPages(calculatedTotalPages);
            setLoading(false); // Hide loader after updating state
          }, 1000);
        }
      } catch (error) {
        tostE("Error fetching coupon templates:", error);
        setLoading(false); // Hide loader if an error occurs
      }
    };

    fetchCouponTemplates();
  }, [pageNumber, pageSize]);

  const handlePageChange = (event, value) => {
    if(value < 1 || value > totalPages){
      return
    }
    setLoading(true); // Show loader when pagination change initiated
    setPageNumber(value);
  };

  return (
    <>
      {loading && (
        <div className="preload">
          <img src={logo} alt="Insta-coupon Logo" className="preloadlogo" />
        </div>
      )}

      {!loading && totalRecords == "0" ? (
        <div className="empty-public-bg">
          <img src={imgg} alt="Empty Background" />
          <p>
            No Coupons published yet. Please start with publishing your first
            Coupon by going to Coupon Templates.
          </p>
        </div>
      ) : (
        <div className="additional-content">
          <div className="coupan-info-main">
            <div className="row">
              {couponTemplates.map((template, index) => (
                <CouponCard key={index} data={template} />
              ))}
            </div>
            {totalPages > 1 && (
              <div className="text-center pagination-main mt-4">
                <div className="pagination-nav">
                  <Pagination
                    count={totalPages}
                    shape="rounded"
                    className="pagination"
                    showPrevButton={true}
                    showNextButton={true}
                    onChange={handlePageChange}
                    page={pageNumber}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        className={
                          "page-item " + (item.selected ? "active" : "")
                        }
                        disabled={item.page === pageNumber} // Disable if it's the current page
 
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
