import React, { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { tostE, tostS } from '../../../Utils/Toast';
import * as Yup from 'yup';
import { getOneCouponPublished, updateCouponPublished } from '../../../Services/CoupanServices';
import { MEDIA_BASE_URL } from '../../../Utils/Constants';
import logo from '../../../Assets/images/logo.png';
import Datetime from 'react-datetime';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import moment from 'moment';
import { getDateForMenu1 } from '../../../Utils/CommonFunctions';

export default function EditPublishedCoupon() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [couponPublished, setCouponPublished] = useState(null);
    const [loading, setLoading] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [missingDaysError, setMissingDaysError] = useState('');
   
    useEffect(() => {
        setLoading(true);
        getOneCouponPublished(id)
            .then((res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                        setCouponPublished(res?.data?.data);
                    }, 500);
                }
            })
            .catch((err) => {})
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
    }, []);

    const validationSchema = Yup.object().shape({  selectedDays: Yup.object().when('selectedDuration', {
        is: 'daysOfWeek',
        then: Yup.object().test('has-selected-days', 'Some selected days are missing between Start Date and End Date', function(selectedDays) {
          const { startDate, endDate } = this.parent;
          const daysOfWeek = Object.keys(selectedDays).filter(day => selectedDays[day]);
          const start = new Date(startDate);
          const end = new Date(endDate);
          let missingDays = [];
          while (start <= end) {
            if (daysOfWeek.indexOf(start.toLocaleDateString('en-US', { weekday: 'lowercase' })) === -1) {
              missingDays.push(start.toLocaleDateString('en-US', { weekday: 'lowercase' }));
            }
            start.setDate(start.getDate() + 1);
          }
          return missingDays.length === 0;
        })
      }),
      numberOfSlots: Yup.number()
      .required('Number of slots is required')
      .min(1, 'Number of slots should be between 1 to 100')
      .max(100, 'Number of slots should be between 1 to 100')
      .integer('Number of slots must be an integer')
      .typeError('Please enter a valid number'),
    });

    const initialValues = {
        startDateTime:couponPublished?.startDateTime,
        endDateTime:couponPublished?.endDateTime,
        numberOfSlots: couponPublished?.numberOfSlots,
        dailyShow:couponPublished?.dailyShow==true?'daily':'daysOfWeek',
        weeklyShow:couponPublished?.weeklyShow??null,
        selectedDays: {
            sunday: couponPublished?.weeklyShow?.includes('Sunday')? true : false,
            monday: couponPublished?.weeklyShow?.includes('Monday')? true :false,
            tuesday: couponPublished?.weeklyShow?.includes('Tuesday')? true :false,
            wednesday: couponPublished?.weeklyShow?.includes('Wednesday')? true :false,
            thursday: couponPublished?.weeklyShow?.includes('Thursday')? true :false,
            friday: couponPublished?.weeklyShow?.includes('Friday')? true :false,
            saturday: couponPublished?.weeklyShow?.includes('Saturday')? true :false,
        },  
    };

     
    const resetTime = (date) => {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        return d;
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const {selectedDays , startDateTime, endDateTime} = values
            let capitalizedMissingDays
            let dailyShow1;
            let selectedDayNames;

          
            if (resetTime(new Date(endDateTime)) < resetTime(new Date(startDateTime))) {
                setErrors({ endDateTime: "End Date must be after or equal to Start Date" });
                return; 
            }

            if (new Date(values.endDateTime) > new Date(values.startDateTime)) {
                
                if (values.dailyShow === 'daily') {
                    dailyShow1 = true;
                    capitalizedMissingDays = []; 
                } else if (values.dailyShow === 'daysOfWeek') {
                    dailyShow1 = false; 
                    selectedDayNames = Object.keys(selectedDays).filter(day => selectedDays[day]);
                    capitalizedMissingDays = selectedDayNames.map(day => day.charAt(0).toUpperCase() + day.slice(1));

                } else {
                    dailyShow1 = false;
                }
            } else {
                dailyShow1 = false;
                selectedDayNames = [];
            }


            let finalValue = values.startDateTime ? new Date(values.startDateTime) : null;
            finalValue = moment(finalValue, 'HH:mm:ss').utc().format('HH:mm:ss');
            const [hours, minutes, seconds] = finalValue?.split(':');

            let endDate1=getDateForMenu1(values.endDateTime)._d

            endDate1.setUTCHours(hours);
            endDate1.setUTCMinutes(minutes);
            endDate1.setUTCSeconds(seconds);
      
            const currentTime = new Date();
            const start = new Date(startDateTime);
            const end = new Date(endDate1);
            if (end < currentTime) {
                setErrors({ endDateTime: "The time slot for the same day is no longer valid. Please update or change the end date to reflect a new timefram" });
                return; // Prevent form submission
            }

            setStartTime(new Date()); 
            setLoading(true); 
            const payload = {
                id: id,
                endDateTime: endDate1,
                numberOfSlots: values.numberOfSlots,
                dailyShow:dailyShow1,
                weeklyShow:capitalizedMissingDays,
            };
            updateCouponPublished(payload).then((res)=>{
                tostS(res.data.message);
                navigate(`/merchant/my-coupon/published-coupon/detail/${id}`);
            }).catch((err)=>{
                if(err?.response?.data){
                    tostE(err?.response?.data?.message);
                }
            }).finally(() => {
                setTimeout(() => {
                    setLoading(false); 
                }, 500);
            });
        },
    });

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue ,setErrors} = formik;

        function getDaysInRange(startDateTime, endDateTime) {
            const days = [];
            const startDate = new Date(startDateTime);
            const endDate = new Date(endDateTime);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);
            const currentDate = new Date(startDate);
            while (currentDate <= endDate) {
                days.push(currentDate.getDay()); // Push the day of the week (0-6)
                currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
            }
            return days;
        }



  function getDayIndex(day) {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return daysOfWeek.indexOf(day.toLowerCase());
  }

  function checkMissingDay(selectedDay, startDateTime, endDateTime) {
    const daysInRange = getDaysInRange(startDateTime, endDateTime);
    return !daysInRange.includes(selectedDay);
  }

    const handleSelectedDaysChange = (day) => {
        return (event) => {
          const { checked } = event.target;
          const newSelectedDays = { ...values.selectedDays, [day]: checked };
          setFieldValue('selectedDays', newSelectedDays);
          
          const missingDays = Object.keys(newSelectedDays).filter(day => newSelectedDays[day] && checkMissingDay(getDayIndex(day), new Date(values.startDateTime), new Date(values.endDateTime)));

          const capitalizedMissingDays = missingDays.map(day => day.charAt(0).toUpperCase() + day.slice(1));
          
          if (capitalizedMissingDays.length > 0) {
            setMissingDaysError(`There are no ${capitalizedMissingDays.join(', ')} between the selected Start Date and End Date. Please adjust your selection to include at least one ${capitalizedMissingDays.join(', ')}.`);
          } else {
            setMissingDaysError('');
          }
        };
      };
      const [selectedDuration, setSelectedDuration] = useState(couponPublished?.dailyShow ? 'daily' : 'daysOfWeek');

     useEffect(() => {
        if (values.startDateTime && values.endDateTime) {
            const missingDays = Object.keys(values.selectedDays).filter(
                day => values.selectedDays[day] && checkMissingDay(getDayIndex(day), new Date(values.startDateTime), new Date(values.endDateTime))
            );
    
            const capitalizedMissingDays = missingDays.map(day => day.charAt(0).toUpperCase() + day.slice(1));
            
            if (capitalizedMissingDays.length > 0) {
                setMissingDaysError(`There are no ${capitalizedMissingDays.join(', ')} between the selected Start Date and End Date. Please adjust your selection to include at least one ${capitalizedMissingDays.join(', ')}.`);
            } else {
                setMissingDaysError('');
            }
        }
    }, [values.endDateTime, values.startDateTime, values.selectedDays]);

    const slotStartTime = couponPublished?.slotStartTime; // Assuming couponPublished is your object
    const timeWithoutOffset = slotStartTime ? slotStartTime.split('+')[0] : '';

    const backPage= () => {
        navigate(`/merchant/my-coupon/published-coupon/detail/${id}`)
    }
    return (
        <div className="publish-coupon-sec section-space">
            {loading && (
                <div className="preload">
                    <img src={logo} alt="Insta-coupon Logo" className="preloadlogo" />
                </div>
            )}
            <div className="container">
                <div className="coupan-sec-top-head mb-4">
                    <div className="page-title">
                        <h1>Edit Published Coupon</h1>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                                <Link to="/merchant/my-coupon/published-coupon/list">My Coupons</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/merchant/my-coupon/published-coupon/list">Published Coupons</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to={`/merchant/my-coupon/published-coupon/detail/${id}`}>
                                Published Coupon Detail
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Edit Published Coupon
                            </li>
                        </ol>
                    </nav>
                </div>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <div className="publish-coupon-info-main mt-5">
                            <div className="row">
                                <div className="col-md-8 publish-coupon-box m-auto">
                                    <div className="box-shadow">
                                        <div className="row">
                                            <div className="col-md-12 mb-6 public-box-title-section">
                                                <div className="col-md-2">
                                                    <img
                                                        src={MEDIA_BASE_URL + couponPublished?.couponImage}
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className="col-md-9 mb-4">
                                                    <h4>{couponPublished?.title}</h4>
                                                    <p>
                                                        List Price: <b>${couponPublished?.price}</b> Discounted Price:{' '}
                                                        <b>${couponPublished?.discountedPrice}</b>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Start Date</label>
                                                <Datetime
                                                    value={dayjs(couponPublished?.startDateTime).format('MM/DD/YYYY')}
                                                    inputProps={{ placeholder: 'Select Start Date', disabled: true }}
                                                    timeFormat={false}
                                                    dateFormat="MM/DD/YYYY"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">End Date</label>
                                                <Datetime
                                                    value={dayjs((values?.endDateTime)).format('MM/DD/YYYY')}
                                                    onChange={(date) => setFieldValue('endDateTime', date)}
                                                    inputProps={{ placeholder: 'Select End Date' }}
                                                    dateFormat="MM/DD/YYYY"
                                                    timeFormat={false}
                                                    name="endDateTime"
                                                    isValidDate={(currentDate) => currentDate.isSameOrAfter(new Date(), 'day')}
                                                />
                                                {errors.endDateTime && (
                                                    <span className="form-error">{errors.endDateTime}</span>
                                                )}
                                            </div>

                                            {moment(values.endDateTime).format('YYYY-MM-DD') > moment(values.startDateTime).format('YYYY-MM-DD')  && (
                                                    <div className="col-md-12 mb-3">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="selectedDuration"
                                                        id="dailyRadio"
                                                        value="daily"
                                                        checked={values.dailyShow=='daily'?true:false}
                                                        onChange={( () => setFieldValue("dailyShow",'daily') )}
                                                        />
                                                        <label className="form-check-label" htmlFor="dailyRadio">
                                                        Daily
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="selectedDuration"
                                                        id="daysOfWeekRadio"
                                                        value="daysOfWeek"
                                                        checked={values.dailyShow=='daysOfWeek'?true : false}
                                                        onChange={( () => setFieldValue("dailyShow",'daysOfWeek') )}
                                                        />
                                                        <label className="form-check-label" htmlFor="daysOfWeekRadio">
                                                        Days of Week
                                                        </label>
                                                    </div>
                                                    </div>
                                                )}      
                                             {moment(values.endDateTime).format('YYYY-MM-DD') > moment(values.startDateTime).format('YYYY-MM-DD')  &&  values.dailyShow=='daysOfWeek'&& (
                                                <div className="col-md-12 mb-3">
                                                    <div className="form-check">
                                                    {Object.keys(values?.selectedDays).map(day => (
                                                        <div key={day} className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={day}
                                                                name={`selectedDays.${day}`}
                                                                checked={values.selectedDays[day]}
                                                                onChange={handleSelectedDaysChange(day)}
                                                            />
                                                            <label className="form-check-label" htmlFor={day}>
                                                                {day.charAt(0).toUpperCase() + day.slice(1)}
                                                            </label>
                                                        </div>
                                                    ))}
                                                    </div>
                                                    {missingDaysError && <span className="form-error">{missingDaysError}</span>}
                                                    {selectedDuration === 'daysOfWeek' && Object.values(values.selectedDays).every(day => !day) && (
                                                    <div className="col-md-12 mb-3">
                                                        <span className="form-error">Please select at least one day of the week.</span>
                                                    </div>
                                                    )}
                                                </div>
                                            )
                                            }
                                    
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Slot Start Time</label>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['MobileTimePicker']}>
                                                        <MobileTimePicker
                                                            // minTime={dayjs(`2022-04-17T${timeWithoutOffset}`)}
                                                            minTime={dayjs(couponPublished?.startDateTime)}
                                                            className="mobilepicker-custom"
                                                            value={dayjs(couponPublished?.startDateTime)}
                                                            // value={dayjs(`2022-04-17T${timeWithoutOffset}`)}
                                                            onChange={(time) => {
                                                                const formattedTime = time.format('HH:mm');
                                                                setFieldValue('slotStartTime', formattedTime);
                                                            }}
                                                            inputProps={{
                                                                placeholder: 'Select Start Time',
                                                            }}
                                                            disabled
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Number of Slots</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter between 1 to 100"
                                                    name="numberOfSlots"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.numberOfSlots}
                                                />
                                            {errors.numberOfSlots && touched.numberOfSlots ? <span className="form-error">{errors.numberOfSlots}</span> : null}                                               
                                            </div>
                                            <div className="col-md-12 mt-3 btn-group-box responsive-btn">
                                                <button type="submit" className="btn btn-secondry max-w " onClick={backPage}> Cancel </button>
                                                <button type="submit" className="btn btn-secondry max-w  public-button-back" disabled={loading}> Update </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </FormikProvider>
            </div>
        </div>
    );
}
