import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Radar from 'radar-sdk-js';
import tzlookup from 'tz-lookup';
import { getKeys } from '../../Services/AuthServices';

const GoogleAutocompleteComp = ({ formik }) => {
  const [query, setQuery] = useState(formik.values.address || '');
  const [suggestions, setSuggestions] = useState([]);
  const [addressSelected, setAddressSelected] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [loading, setLoading] = useState(false)

  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);

 const [ PUBLIC_KEY_MAP , setPublicKey] = useState('');
 
 useEffect(() => {
    const timer = setTimeout(() => {
        setDebouncedQuery(query);
    }, 500); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
        clearTimeout(timer);
    };
}, [query]);

useEffect(() => {
    getKeys().then((res)=>{
        if(res.status==200){
          const mapApiKey = res?.data?.data?.REACT_APP_RADAR_API_KEY
          setPublicKey(res?.data?.data?.REACT_APP_RADAR_API_KEY)
          Radar.initialize(mapApiKey);
          return () => {
              Radar.setUserId(null);
          };
        }
      }).catch((err)=>{ 
        console.log(err)
      })    
}, []);

useEffect(() => {
    const fetchAutocompleteSuggestions = async (address) => {
        try {
            setLoading(true)
            const responseUS = [
               await axios.get('https://api.radar.io/v1/search/autocomplete', {
                    params: {
                        countryCode: 'US',
                        query: address,
                        limit:5,
                    },
                    headers: {
                        Authorization: PUBLIC_KEY_MAP
                    }
                }),
            ];
            console.log(responseUS)
            setSuggestions(responseUS?.[0]?.data?.addresses);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Error fetching autocomplete suggestions:', error);
        }
    };

    if (debouncedQuery) {
        fetchAutocompleteSuggestions(debouncedQuery);
    } else {
        setSuggestions([])
    }

}, [debouncedQuery]);
  const handleInputChange = (event) => {
    setQuery(event.target.value);
    formik.setFieldValue("address", event.target.value);
    setSuggestions([])
    setAddressSelected(false);
};

const handleSuggestionClick = async (address) => {

    handlePlaceChanged(address);
    console.log(address)
};
  
  const handlePlaceChanged = async (place) => {
    try {
      const lat = place?.latitude;
      const lng = place?.longitude;
      const timeZone = tzlookup(lat, lng);

      formik.setValues({
        ...formik?.values,
        zipCode: place?.postalCode ? place?.postalCode : '',
        city: place?.city ? place?.city : '',
        state: place?.state ? place?.state :'',
        address: place?.addressLabel ? place?.addressLabel : place?.street ? place?.street : '',
        lat: lat,
        lng: lng,
        timeZone: timeZone,
        formattedAddress:place?.formattedAddress ? place?.formattedAddress : ''
      });
      setSuggestions([]);
    } catch (error) {
      console.error('Error fetching time zone:', error);
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
        if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
            // Click occurred outside the suggestions box
            setSuggestions([]);
        }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
        // Unbind the event listener on cleanup
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, []);


  return (
    <div>
      <input
        id="autocomplete"
        type="text"
        placeholder="Enter address"
        value={formik.values.address}
        onChange={handleInputChange}
        className='form-control'
        ref={inputRef}
        autoComplete='off'
        error={Boolean(formik.touched.address && formik.errors.address)}
        helperText={formik.touched.address && formik.errors.address}
      />
      
      {suggestions?.length > 0 && !addressSelected && (
                <ul ref={suggestionsRef} className='suggestion-box form-control'>
                    {suggestions.map((address, index) => (
                        <li className='suggestion-box-list' key={index} onClick={() => handleSuggestionClick(address)}>
                            {address?.formattedAddress}
                        </li>
                    ))}
                </ul>
            )}

            {loading ?
             <ul ref={suggestionsRef} className='suggestion-box form-control'>
                <li  className='suggestion-box-list'>Loading...</li>
            </ul> 
              : ""}
    </div>
  );
};

export default GoogleAutocompleteComp;
