import React, { useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import OTPInput from 'react-otp-input';
import { COUNTRY_CODE } from '../../Utils/Constants';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOtpScreenSettings, phoneVerifyService, resendPhoneOtp } from '../../Services/AuthServices';
import { tostE, tostS } from '../../Utils/Toast';
import { setUser, setUserToken } from '../../Actions/ActionCreator';
import logo from './../../Assets/images/logo.png'

export default function PhoneVerify() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector((state)=>state.user && state.user.data ? state.user.data : null);
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process
    const [emailOtpScreen, setEmailOtpScreen] = useState(1);

    const otpValidation = Yup.object().shape({
        otp: Yup.string().required('Please enter otp').min(4, "Enter a valid OTP")
    });
    useEffect(() => {
        getOtpScreenSettings().then((res) => {
          if (res.status == 200) {
            setEmailOtpScreen(res.data.data.email_otp_screen)
          }
        }).catch((err) => { console.log(err) })
      }, [])
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: ""
        },
        validationSchema: otpValidation,
        onSubmit: async (values) => {
            setStartTime(new Date()); // Record start time
            setLoading(true); // Show loader before making API call
            const payload = {
                phoneNumber: currentUser?.phoneNumber,
                countryCode: COUNTRY_CODE,
                phoneOtp: values.otp
            }
            phoneVerifyService(payload).then((res)=>{
                if(res.status===200){
                    localStorage.removeItem('merchantId');
                    const respData = res.data.data;
                    if(respData?.merchantDetails && respData?.token && respData?.merchantDetails?.emailVerify==true){
                        const userData = res.data.data.merchantDetails;
                        const userToken = res.data.data.token;
                        dispatch(setUserToken(userData, userToken)) 
                    }else{
                        if(respData?.merchantDetails){
                            dispatch(setUser(respData?.merchantDetails))
                        }else{
                            dispatch(setUser(respData))
                        }
                        setTimeout(() => {
                            setLoading(false); // Hide loader after API call completes
                            setStartTime(null); // Reset start time
                            emailOtpScreen?  navigate("/auth/email-verify"):navigate("/auth/login")
                        }, 1000);   
                       
                    }
                }
            }).catch((err)=>{
                if(err?.response?.data){
                    tostE(err?.response?.data?.message);
                }
            }).finally(() => {
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                }, 500);
            });     
        }
    });

    const { errors, values, touched, handleSubmit, setValues } = formik;

    const handleResend = () => {
        setStartTime(new Date()); // Record start time
        setLoading(true); // Show loader before making API call
        const payload = {
            phoneNumber: currentUser?.phoneNumber,
            countryCode: COUNTRY_CODE
        }

        resendPhoneOtp(payload).then((res)=>{
            if(res.status===200){
                setTimeout(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
                    tostS(res?.data?.message)
                }, 1000);                 
            }
        }).catch((err)=>{
            if(err?.response?.data){
                tostE(err?.response?.data?.message);
            }
        }).finally(() => {
                setLoading(false); // Hide loader after API call completes
        });     
    }

    return (
        <>
         {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}        
            <div className="form-head mb-4 text-center">
                <h4>Verify your phone number</h4>
                <p>Enter the OTP sent to your phone</p>
            </div>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <div className="form-body">
                        <div className="form-group">
                            <OTPInput
                                renderSeparator={false}
                                containerStyle={"otp-form d-flex mt-3"}
                                inputStyle={{ width: "100%" }}
                                placeholder='----'
                                numInputs={4}
                                value={values.otp}
                                inputType='number'
                                onChange={(otp) => { setValues({ ...values, otp: otp }) }}
                                renderInput={(props) => <input placeholder="-" class={"form-control"} {...props} />}
                            />
                            {errors.otp && touched.otp ? <span className="form-error">{errors.otp}</span> : null}
                        </div>
                        <div className="form-btn-box mt-3">
                            <button type='submit' className="btn btn-secondry w-100">Verify </button>
                        </div>
                        <div className="recive-otp form-bottom-text text-center mt-3">
                            <span>Didn’t receive OTP? <button type='button' onClick={()=>{handleResend()}} className="form-link">Resend</button></span>
                        </div>
                    </div>
                </Form>
            </FormikProvider>
            <div className="form-bottom form-bottom-text">
                <Link
                    to={"/auth/signup"}
                    className="form-link"
                >
                    Back to Sign Up
                </Link>                
            </div>
        </>
    )
}
