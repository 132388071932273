import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsProvider, useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { FormikProvider, useFormik } from 'formik';
import { STRIPT_PUBLIC_KEY } from '../../Utils/Constants';
import { subscriptionPay } from '../../Services/CommonServices';
import { tostE, tostS } from '../../Utils/Toast';
import { useNavigate } from 'react-router-dom';
import { updateAcceptTerms } from '../../Actions/ActionCreator';
import logo from '../../Assets/images/logo.png';
import { Modal, ModalBody } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getKeys } from '../../Services/AuthServices';

// const stripePromise = loadStripe(STRIPT_PUBLIC_KEY);
const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentPrice, setPaymentPrice] = useState('');
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [cardnumbberErr , setCardNumberError] =useState(null)
  const [cardExpiryErr , setCardExpiryError] =useState(null)
  const [cardCvvErr , setCardCvvError] =useState(null)
  const [loading, setLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const user = useSelector(state => state.user);
  const [startTime, setStartTime] = useState(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    if (user && user.data && user.data.subscriptionPrice) {
        setPaymentPrice(user.data.subscriptionPrice);
        console.log(user.data.subscriptionPrice)
    }
}, [user]);

  const validateFirstName = (firstName) => {
    if (!firstName.trim()) {
      return 'First Name is required';
    }
    return null;
  };

  const validateLastName = (lastName) => {
    if (!lastName.trim()) {
      return 'Last Name is required';
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    
    onSubmit: async (values) => {

      const firstNameError = validateFirstName(values.firstName);
      setFirstNameError(firstNameError);

      const lastNameError = validateLastName(values.lastName);
      setLastNameError(lastNameError);


      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      const { token, error } = await stripe.createToken(cardElement, {
        name: `${values.firstName} ${values.lastName}`,
      });
    
      if (error) {
        console.error(error);
        return;
      }
    
      const tokenId = token.id;
    

      const payload = {
        token: token.id,
      };  

      setStartTime(new Date());
      setLoading(true); 
      subscriptionPay(payload).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setPopupVisible(true);

          const popupDuration = 3000; // 3 seconds
          setTimeout(() => {
            setPopupVisible(false);
            setLoading(true); // Restart loader
            setTimeout(() => {
              setLoading(false);
              dispatch(updateAcceptTerms(true));
            }, 500); 
          }, popupDuration);
        }
      }).catch((err) => {
        if (err?.response?.data) {
          setLoading(false); 
          setStartTime(null);
          tostE(err?.response?.data?.message);
        }
      }).finally(() => {
        setTimeout(() => {
          setLoading(false); 
          setStartTime(null);
        }, 500);
      });

      setFirstNameError(null);
      setLastNameError(null);
    },
  });
  const handleFirstNameChange = (event) => {
    const { value } = event.target;
  
    if (value === '') {
      setFirstNameError('First Name is required');
      formik.setFieldValue('firstName', value);
      return;
    }
  
    if (/^\s/.test(value) || value.trim().charAt(0).toLowerCase() === ' ') {
      setFirstNameError("First name cannot start with blank space");
      return;
    }
  
    if (!/^[a-zA-Z\s]+$/.test(value)) {
      return;
    }
  
    setFirstNameError(null);
    formik.handleChange(event);
  };
  
  const handleLastNameChange = (event) => {
    const { value } = event.target;
  
    if (value === '') {
      setLastNameError('Last Name is required');
      formik.setFieldValue('lastName', value);
      return;
    }
  
    if (/^\s/.test(value)) {
      setLastNameError('Last name cannot start with blank space');
      return;
    }
  
    if (!/^[a-zA-Z\s]+$/.test(value)) {
      return;
    }
  
    setLastNameError(null);
    formik.handleChange(event);
  };
  
  
  const handleCardNumberChange = (event) => {
    const errors = [];
    if (event.error) {
      errors.push({ type: 'cardNumber', message: event.error.message });
    }
    setCardNumberError(errors);
  };

  const handleExpiryChange = (event) => {
    const errors = [];
    if (event.error) {
      errors.push({ type: 'cardExpiry', message: event.error.message });
    }
    setCardExpiryError(errors);
  };
  
  const handleCvcChange = (event) => {
    const errors = [];
    if (event.error) {
      errors.push({ type: 'cardCvc', message: event.error.message });
    }
    setCardCvvError(errors);
  };

  return (
    <div className="publish-coupon-sec section-space">
      {loading && (
        <div className='preload'>
          <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
        </div>
      )}
      <div className="container">
        <div className="coupan-sec-top-head mb-4">
        </div>
        <FormikProvider value={formik}>
          <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
            <div className="publish-coupon-info-main mt-5">
              <div className="row">
                <div className="col-md-10 publish-coupon-box m-auto">
                  <div className="box-shadow">
                    <div className="row">
                      <div className="col-md-12 mb-6 public-box-title-section">
                        <div className="col-md-9 mb-4">
                          <h4>Subscription Payment</h4>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">First Name</label>
                        <input
                          type="text"
                          placeholder='First Name'
                          className="form-control custom-pay-input"
                          name="firstName"
                          onChange={handleFirstNameChange}
                          value={formik.values.firstName}
                        />
                        {firstNameError && <span className="form-error">{firstNameError}</span>}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Last Name</label>
                        <input
                          type="text"
                          placeholder='Last Name'
                          className="form-control custom-pay-input"
                          name="lastName"
                          onChange={handleLastNameChange}
                          value={formik.values.lastName}
                        />
                        {lastNameError && <span className="form-error">{lastNameError}</span>}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Card Number</label>
                        <CardNumberElement
                          placeholder={'cardNumberPlaceholder'}
                          onChange={handleCardNumberChange}
                          className={cardnumbberErr?.find(cardnumbberErr => cardnumbberErr.type === 'cardNumber') ? "form-control custom-payment invalid" : "form-control custom-payment"}
                          options={{
                            placeholder: 'XXXX XXXX XXXX XXXX'
                          }}
                      />
                        {cardnumbberErr?.find(cardnumbberErr => cardnumbberErr.type === 'cardNumber') && <span className="form-error">{cardnumbberErr.find(cardnumbberErr => cardnumbberErr.type === 'cardNumber').message}</span>}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Expiration</label>
                        <CardExpiryElement
                          onChange={handleExpiryChange}
                          className={cardExpiryErr?.find(cardExpiryErr => cardExpiryErr.type === 'cardExpiry') ? "form-control custom-payment invalid" : "form-control custom-payment"}
                        />
                        {cardExpiryErr?.find(cardExpiryErr => cardExpiryErr.type === 'cardExpiry') && <span className="form-error">{cardExpiryErr.find(cardExpiryErr => cardExpiryErr.type === 'cardExpiry').message}</span>}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">CVC</label>
                        <CardCvcElement
                           onChange={handleCvcChange}
                          className={cardCvvErr?.find(cardCvvErr => cardCvvErr.type === 'cardCvc') ? "form-control custom-payment invalid" : "form-control custom-payment"}
                        />
                        {cardCvvErr?.find(cardCvvErr => cardCvvErr.type === 'cardCvc') && <span className="form-error">{cardCvvErr.find(cardCvvErr => cardCvvErr.type === 'cardCvc').message}</span>}
                      </div>
                      <div className="col-md-12 text-center mt-4 btn-group-box">
                        <button type="submit" className="btn btn-secondry max-w">Pay Now (${paymentPrice})</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>

      <Modal className='modal fade form-modal' show={popupVisible} backdrop="static">
        <ModalBody>
          <div className="model-head logout-modal">
            <figure><i class="fas fa-award"></i></figure>
          </div>
          <figcaption>
            <h4>Congratulations! Your plan is ready. Enjoy the services.</h4>
          </figcaption>
        </ModalBody>
      </Modal>
    </div>
  );
};


const Pay = () => {
  const [stripePromise, setStripePromise] = useState(null);
  useEffect(() => {
    const fetchStripePublicKey = async () => {
      try {
        const res = await getKeys();
        if (res.status === 200) {
          const stripeKey = res?.data?.data?.REACT_APP_STRIPT_PUBLIC_KEY;
          const stripe = loadStripe(stripeKey);
          setStripePromise(stripe);
        } else {
          console.error('Failed to load Stripe public key');
        }
      } catch (error) {
        console.error('Failed to load Stripe public key', error);
      }
    };

    fetchStripePublicKey();
  }, []);

  if (!stripePromise) {
    return   (
          <>
            <div className="preload">
               <img src={logo} alt="Insta-coupon Logo" className="preloadlogo" />
            </div>
          </>
    )
  }


  return (
        <div className="payment-container">
              <Elements stripe={stripePromise}>
                <PaymentForm />
              </Elements>
            </div>
  );
};

export default Pay;
