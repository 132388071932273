import React from 'react';
import ReactDOM from 'react-dom/client';

import './Assets/css/all.css';
import './Assets/css/bootstrap.min.css';
import './Assets/css/animation.css';
import './Assets/css/style.css';
import './Assets/css/custom-responsive.css';
import './Assets/css/tabler-icons.css';
import './Assets/fonts/remixicon.css';
import './Assets/css/custom.css';
import './Assets/css/responsive.css';

import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
    <BrowserRouter>
      <App />
    </BrowserRouter>  
);

reportWebVitals();
