import React, { useEffect, useState } from 'react';
import { getAnalyticsDetails } from '../../../Services/AnalyticsService';
import logo from "../../../Assets/images/logo.png";
import { tostE } from '../../../Utils/Toast';
import AreaChart from '../../../Components/Dashboard/GrowthChart';
import { formatPrice } from '../../../Utils/CommonFunctions';

export default function AnalyticsScreen() {
    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState({});
    const [growthData, setGrowthData] = useState([]);
    const [growthDataByWeek, setGrowthDataByWeek] = useState([]);
    const [period, setPeriod] = useState('month'); // Default to month

    useEffect(() => {
        const fetchAnalyticsDetails = async () => {
            setLoading(true);
            try {
                const response = await getAnalyticsDetails();
                if (response.status === 200) {
                    setTimeout(() => {
                        setAllData(response?.data?.data);
                        setGrowthData(response?.data?.data?.growthData);
                        setGrowthDataByWeek(response?.data?.data?.growthDataByWeek);
                        setLoading(false);
                    }, 1000);
                }
            } catch (error) {
                tostE("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchAnalyticsDetails();
    }, []);

    const handlePeriodChange = (newPeriod) => {
        setPeriod(newPeriod);
    };

    const formatCustomerCount = (count) => {
      if (count >= 1000) {
          return `${(count / 1000).toFixed(1)}K`;
      } else {
          return count;
      }
  };


    return (
        <div className="dashboard-sec section-space">
            {loading && (
                <div className="preload">
                    <img src={logo} alt="Logo" className="preloadlogo" />
                </div>
            )}
            <div className="container">
                <div className="page-title mb-4">
                    <h1>Analytics</h1>
                </div>
                <div className="dashboard-info-main">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="dashboard-info d-flex justify-content-between align-items-center yallow-dash-info">
                                <div className="dashboard-info-left">
                                    <h3>${formatPrice(allData?.totalActiveCoupons)}</h3>
                                    <p>Active Coupons</p>
                                </div>
                                <div className="dashboard-info-right">
                                    <span><i className="ti ti-ticket"></i></span>
                                </div>
                            </div>
                            <div className="backshadow"></div>           
                        </div>
                        <div className="col-md-3">
                            <div className="dashboard-info d-flex justify-content-between align-items-center green-dash-info">
                                <div className="dashboard-info-left">
                                    <h3>${formatPrice(allData?.totalUsedCoupons)}</h3>
                                    <p>Used Coupons</p>
                                </div>
                                <div className="dashboard-info-right">
                                    <span><i className="ti ti-ticket"></i></span>
                                </div>
                            </div>
                            <div className="backshadow green-shadow"></div> 
                        </div>
                        <div className="col-md-3">
                            <div className="dashboard-info d-flex justify-content-between align-items-center pink-dash-info">
                                <div className="dashboard-info-left">
                                    <h3>${formatPrice(allData?.totalExpiredCoupons)}</h3>
                                    <p>Unredeemed Coupons</p>
                                </div>
                                <div className="dashboard-info-right">
                                    <span><i className="ti ti-ticket"></i></span>
                                </div>
                            </div>
                            <div className="backshadow pink-shadow"></div>
                        </div>
                        <div className="col-md-3">
                            <div className="dashboard-info d-flex justify-content-between align-items-center blue-dash-info">
                                <div className="dashboard-info-left">
                                    <h3>{formatCustomerCount(allData?.totalCustomer)}</h3>
                                    <p>Customers Acquired</p>
                                </div>
                                <div className="dashboard-info-right">
                                    <span><i className="ti ti-ticket"></i></span>
                                </div>
                            </div>
                            <div className="backshadow blue-shadow"></div> 
                        </div>
                    </div>
                </div>
                <div className="box-shadow mt-5">
                    <div className="section-title">
                        <div class="calendar-tab-head">
                        <h2>Growth Chart
                        <br/>
                        <p>Your Business Growth by {period}</p>
                        </h2>
                        <div className="tab-head">
                              <ul>
                                 <li className={period === "day" ? "active mode-cursion-calender" : 'mode-cursion-calender'}>
                                    <a onClick={() => handlePeriodChange('day')}>Days</a>
                                 </li>
                                 <li className={period === "week" ? "active mode-cursion-calender" : 'mode-cursion-calender'}>
                                    <a 
                                    onClick={() => handlePeriodChange('week')}
                                    >Weeks</a>
                                 </li>
                                 <li className={period === "month" ? "active mode-cursion-calender" : 'mode-cursion-calender'}>
                                    <a onClick={() => handlePeriodChange('month')}>Months</a>
                                 </li>
                              </ul>
                           </div>
                    </div>
                    </div>
                    <div className="graph-box p-4">
                        <AreaChart 
                            growthData={growthData} 
                            growthDataByWeek={growthDataByWeek} 
                            period={period} 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}