import React, { useState, useCallback, useEffect } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default function AreaChart({ growthData, growthDataByWeek, period }) {
    const [currentPeriod, setCurrentPeriod] = useState(new Date());

    useEffect(() => {
        if (period === 'month') {
            setCurrentPeriod(new Date());  
        } else if (period === 'week' || period === 'day') {
            setCurrentPeriod(new Date());
        }
    }, [period]);

    const formatDate = (date) => {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    };

    const formatWeekLabel = (weekNumber) => {
        const startDate = new Date(currentPeriod.getFullYear(), 0, (weekNumber - 1) * 7 + 1);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        const startMonth = monthNames[startDate.getMonth()];
        const endMonth = monthNames[endDate.getMonth()];
        return startMonth === endMonth
            ?  ` ${startDate.getDate()}/${startMonth} - ${endDate.getDate()}/${endMonth}`
            : `  ${startDate.getDate()}/${startMonth} - ${endDate.getDate()}/${endMonth}`;
    };

    const formatDayLabel = (date) => {
        return `${monthNames[date.getMonth()]} ${date.getDate()}`;
    };

    const getWeekNumber = (date) => {
        const janFirst = new Date(date.getFullYear(), 0, 1);
        const days = Math.floor((date - janFirst) / (24 * 60 * 60 * 1000));
        return Math.ceil((days + 1) / 7);
    };

    const generateData = (data, key, period) => {
        const dataArray = [];
        const year = currentPeriod.getFullYear();
        const month = currentPeriod.getMonth();
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const periodCounts = {};
        if (data) {
            data.forEach(item => {
                let periodKey;
                if (period === 'day') {
                    periodKey = `${item.year}-${item.month}-${item.day}`;
                } else if (period === 'week') {
                    periodKey = `${item.year}-W${item.week}`;
                } else if (period === 'month') {
                    periodKey = `${item.year}-${item.month}`;
                }
                periodCounts[periodKey] = (periodCounts[periodKey] || 0) + parseInt(item[key], 10);
            });
        }

        if (period === 'day') {
            const daysInMonth = new Date(year, currentPeriod.getMonth() + 1, 0).getDate();
            for (let i = 1; i <= daysInMonth; i++) {
                const dayDate = new Date(year, currentPeriod.getMonth(), i);
                const periodKey = formatDate(dayDate);
                dataArray.push({
                    x: formatDayLabel(dayDate),
                    y: periodCounts[periodKey] || 0
                });
            }
        } else if (period === 'week') {
            const firstDayOfMonth = new Date(year, month, 1);
            const lastDayOfMonth = new Date(year, month + 1, 0);
            const startWeek = getWeekNumber(firstDayOfMonth);
            const endWeek = getWeekNumber(lastDayOfMonth);

            for (let week = startWeek; week <= endWeek; week++) {
                const periodKey = `${year}-W${week}`;
                dataArray.push({
                    x: formatWeekLabel(week),
                    y: periodCounts[periodKey] || 0
                });
            }
        } else if (period === 'month') {
            for (let i = 1; i <= 12; i++) {
                const periodKey = `${year}-${i}`;
                dataArray.push({
                    x: monthNames[i - 1],
                    y: periodCounts[periodKey] || 0
                });
            }
        }

        return dataArray;
    };

    const calculateMaxValue = (dataArray) => {
        return Math.max(...dataArray.map(item => item.y));
    };
    
    const determineTickAmount = (maxValue, minTicks = 5) => {
        const gap = Math.ceil(maxValue / minTicks);
        const tickAmount = Math.ceil(maxValue / gap);
        return { tickAmount, gap };
    };
    
    const seriesData1 = generateData(period === 'week' ? growthDataByWeek : growthData, 'purchaseCount', period);
    const seriesData2 = generateData(period === 'week' ? growthDataByWeek : growthData, 'userCount', period);
    
    const maxValue = Math.max(calculateMaxValue(seriesData1), calculateMaxValue(seriesData2));
    const { tickAmount, gap } = determineTickAmount(maxValue);
    
    const options = {
        chart: {
            id: 'area',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        xaxis: {
            type: 'category',
            categories: seriesData2.map(item => item.x),
            tickPlacement: 'on',
        },
        yaxis: {
            min: 0,
            max: gap * tickAmount,  // Ensuring the max aligns with the gap and tickAmount
            tickAmount: tickAmount,  // Minimum number of ticks
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0,
                opacityTo: 0,
                stops: [0, 90, 100]
            }
        }
    };
    
    const series = [
        {
            name: 'Purchased Coupons',
            data: seriesData1,
            color: '#FA5856'
        },
        {
            name: 'Customers Acquired',
            data: seriesData2,
            color: '#00b3bc'
        }
    ];
    

    const handlePrev = useCallback(() => {
        let newPeriod = moment(currentPeriod);
        if (period === 'day') {
            newPeriod = newPeriod.subtract(1, 'months');
        } else if (period === 'week') {
            newPeriod = newPeriod.subtract(1, 'months');
        } else if (period === 'month') {
            newPeriod = newPeriod.subtract(1, 'years');
        }
        setCurrentPeriod(newPeriod.toDate());  // Convert back to Date if needed
    }, [currentPeriod, period]);

    const handleNext = useCallback(() => {
        let newPeriod = moment(currentPeriod);
        if (period === 'day') {
            newPeriod = newPeriod.add(1, 'months');
        } else if (period === 'week') {
            newPeriod = newPeriod.add(1, 'months');
        } else if (period === 'month') {
            newPeriod = newPeriod.add(1, 'years');
        }
        setCurrentPeriod(newPeriod.toDate());  // Convert back to Date if needed
    }, [currentPeriod, period]);

    const formatCurrentPeriod = (period) => {
        const year = currentPeriod.getFullYear();
        const month = monthNames[currentPeriod.getMonth()];
        const day = currentPeriod.getDate();
        const weekNumber = getWeekNumber(currentPeriod);

        if (period === 'day') {
            return `${month} ${year}`;
        } else if (period === 'week') {
            return `${month} ${year}`;
        } else if (period === 'month') {
            return `${month} ${year}`;
        }
    };

    return (
        <div>
            <div id="calendar" className="calendar-custom fc fc-media-screen fc-direction-ltr fc-theme-standard">
                <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr custom-toolbar-box">
                    <div className="fc-toolbar-chunk">
                        <button
                            type="button"
                            aria-pressed="false"
                            className="fc-prev-button fc-button fc-button-primary"
                            onClick={handlePrev}
                        >
                            <span className="fc-icon fc-icon-chevron-left" role="img"></span>
                        </button>
                    </div>
                    <div className="fc-toolbar-chunk">
                        <h2 className="fc-toolbar-title" id="fc-dom-1456"> {formatCurrentPeriod(period)}</h2>
                    </div>
                    <div className="fc-toolbar-chunk">
                        <div className="fc-button-group">
                            <button
                                type="button"
                                aria-pressed="false"
                                className="fc-next-button fc-button fc-button-primary"
                                onClick={handleNext}
                            >
                                <span className="fc-icon fc-icon-chevron-right" role="img"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Chart options={options} series={series} type="area" height={350} />
        </div>
    );
}
