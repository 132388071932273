import axios from "axios";
import moment from "moment-timezone";
import statesDataCheck from "../Components/Common/StatecountryCode";
import { getKeys } from "../Services/AuthServices";
import { tostE } from "./Toast";
  
export const InitialRender = (data) => {
  if (data != null && data != undefined && data) {
    
    let tempArr = data;
    let initials = "";
    tempArr.map((i) => {
      if (i) {
        initials = initials + i[0];
      }
    });
    initials = initials.length > 2 ? initials.slice(0, 2).toUpperCase() : initials.toUpperCase();
    return initials;
  }
  return "NA";
};


const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const timezone = systemTimeZone;
export function getDateForMenu(dateStr = null) {

  let date;
  if (dateStr) {
    date = moment.tz(dateStr, timezone);
  } else {
    date = moment.tz(timezone);
  }
  const isDST = date.isDST();
  const isDSTNow = moment(new Date()).isDST();
  
  if (isDST && !isDSTNow) {
    date.subtract(1, "hour");
  } else if (!isDST && isDSTNow) {
    date.add(1, "hour");
  }

  date = moment(date).format('MMM DD')
  return date;
}


export function getDateForMenu1(dateStr = null) {

  let date;
  if (dateStr) {
    date = moment.tz(dateStr, timezone);
  } else {
    date = moment.tz(timezone);
  }
  const isDST = date.isDST();
  const isDSTNow = moment(new Date()).isDST();
  
  if (isDST && !isDSTNow) {
    date.subtract(1, "hour");
  } else if (!isDST && isDSTNow) {
    date.add(1, "hour");
  }
  return date;
}

export function adjustDateTimeForDST(dateStr = null) {

  let date = moment(dateStr);

  const isDST = date.isDST();
  const isDSTNow = moment(new Date()).isDST();
  
  if (isDST && !isDSTNow) {
    date.subtract(1, "hour");
  } else if (!isDST && isDSTNow) {
    date.add(1, "hour");
  }
  return date;
}

export const TextLimitShow = (description, limit) => {
  const words = description?.split(' ');
  if (words.length > limit) {
    return words.slice(0, limit).join(' ') + '...';
  }
  return description;
};

export const getStateCode = (stateName) => {
  return statesDataCheck[stateName.trim()] || stateName.trim();
};

export const formatDate1 = (dateString) => {
  const dateUTC = new Date(dateString);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let dateInIST = dateUTC.toLocaleString('en-US', { timeZone: userTimeZone });
  dateInIST =  moment(dateInIST).format('MMM DD')
  return dateInIST;
};


export const formatDate = (
  slotStartTime,
  duration,
  durationText,
) => {        
  slotStartTime = moment.utc(slotStartTime , "HH:mm:ss").local().format('HH:mm:ss');
  if (!slotStartTime) return ''; 

  const [startHour, startMinute] = slotStartTime.split(':').map(Number);
  let startTotalMinutes = startHour * 60 + startMinute;
  if (durationText === 'Hours') {
      duration *= 60;
  }
  let endTotalMinutes = startTotalMinutes + duration;
  let endHour = Math?.floor(endTotalMinutes / 60) % 24;
  let endMinute = endTotalMinutes % 60;
  let formattedStartTime = formatTime(startHour, startMinute);
  let formattedEndTime = formatTime(endHour, endMinute);

  return `${formattedStartTime}-${formattedEndTime}`;
};

function formatTime(hour, minute) {
  let amPM = hour >= 12 ? 'PM' : 'AM';
  let formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  let formattedMinute = minute < 10 ? '0' + minute : minute;
  return `${formattedHour}:${formattedMinute}${amPM}`;
}

export   function formatPrice(amount) {
  if (typeof amount !== 'number' || isNaN(amount)) {
      return 'Invalid amount';
  }

  if (amount >= 1000) {
      return (amount / 1000).toFixed(amount % 1000 === 0 ? 0 : 1) + 'K';
  } else {
      return amount.toFixed(2);
  }
}



export const checkAddressValidity = async (values) => {
  const {formattedAddress , address, city, state, zipCode } = values;
  const fullAddress = formattedAddress;
  const CityLatLng =`${city}, ${state}`;

  try {
    const res = await getKeys();
    if (res.status !== 200) {
      console.error("Failed to retrieve API key");
      return false;
    }

  const apiKey = res?.data?.data?.REACT_APP_RADAR_API_KEY;
  const PUBLIC_KEY_MAP = apiKey;

  const fullAddressResponse = 
    await axios.get('https://api.radar.io/v1/search/autocomplete', {
          params: {
              countryCode: 'US',
              query: fullAddress,
              layer: "coarse"
          },
          headers: {
              Authorization: PUBLIC_KEY_MAP
          }
      });

      const fullAddressData =fullAddressResponse?.data;
          let successcode =fullAddressData?.meta?.code;
         
          if (successcode === 200 && fullAddressData?.addresses?.length > 0) {
            const matchedAddress = fullAddressData.addresses.find(addr => addr.formattedAddress == fullAddress);
            if(matchedAddress){
              return {
                lat: matchedAddress.latitude,
                lng: matchedAddress.longitude
            };   
            }
            else{
            const CityLatLngResponse = await axios.get('https://api.radar.io/v1/search/autocomplete', {
              params: {
                  countryCode: 'US',
                  query: CityLatLng,
              },
              headers: {
                  Authorization: PUBLIC_KEY_MAP
              }
            });
              const CityLatLngData =CityLatLngResponse?.data;
              const { latitude: fullAddressLat, longitude: fullAddressLng } = CityLatLngData.addresses[0];
              
              if (CityLatLngData.meta.code === 200 && CityLatLngData.addresses.length > 0) {
                const radarCityState = CityLatLngData.addresses[0];
                const { city: radarCity, state: radarState, latitude, longitude } = radarCityState;
          
                if (radarCity.toLowerCase() === city.toLowerCase() && radarState.toLowerCase() === state.toLowerCase()) {
                  return {
                    lat: fullAddressLat,
                    lng: fullAddressLng
                };  
                } else {
                  tostE('The entered city is not within the selected state.');
                  return false;
                }
              } else {
                tostE('City not found within the selected state. Please provide a correct city and state combination.');
                return false;
              }
          }                       
        }  else {
          const CityLatLngResponse = await axios.get('https://api.radar.io/v1/search/autocomplete', {
                params: {
                    countryCode: 'US',
                    query: CityLatLng,
                },
                headers: {
                    Authorization: PUBLIC_KEY_MAP
                }
            });
            const CityLatLngData =CityLatLngResponse?.data;
            const { latitude: fullAddressLat, longitude: fullAddressLng } = CityLatLngData.addresses[0];
            
            if (CityLatLngData.meta.code === 200 && CityLatLngData.addresses.length > 0) {
              const radarCityState = CityLatLngData.addresses[0];
              const { city: radarCity, state: radarState, latitude, longitude } = radarCityState;
        
              if (radarCity.toLowerCase() === city.toLowerCase() && radarState.toLowerCase() === state.toLowerCase()) {
                return {
                  lat: fullAddressLat,
                  lng: fullAddressLng
              };  
              } else {
                tostE('The entered city is not within the selected state.');
                return false;
              }
            } else {
              tostE('City not found within the selected state. Please provide a correct city and state combination.');
              return false;
            }
        }
      }
        catch (err) {
          console.error("Error occurred during address validation", err);
          return false;
        }
 }