import {SET_USER, SET_USER_TOKEN } from './Types' ;
import {CLEAN_ALL_DATA} from './Types'
import { UPDATE_ACCEPT_TERMS } from './Types';


 // =====================================================================
 
export const cleanAllData = () => ({
   type : CLEAN_ALL_DATA,
})

export const setUser = (payload) => ({
   type : SET_USER,
   payload
})

export const setUserToken = (payload, token) => ({
   type : SET_USER_TOKEN,
   payload,
   token
})

export const updateAcceptTerms = (status) => {
   return {
       type: UPDATE_ACCEPT_TERMS,
       payload: status
   };
};