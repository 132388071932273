import {CLEAN_ALL_DATA, CLEAN_USER_DATA, SET_USER, SET_USER_TOKEN ,UPDATE_ACCEPT_TERMS} from '../Actions/Types';

const user = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: {},
            token:""
        }
    }
    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: {},
                token:""                
            }
        case CLEAN_USER_DATA:
            return {
                ...state,
                data: {},
                token:""                
            }
        
        case SET_USER:
            return {
                ...state,
                data:action.payload
            }         
        
        case SET_USER_TOKEN:
            return {
                ...state,
                data:action.payload,
                token:action.token
            }
        case UPDATE_ACCEPT_TERMS:
            return {
                ...state,
                data: {
                    ...state.data,
                    acceptTerms: action.payload
                }
            };
        default:
            return state

    }
}



export default user;
