import React from 'react'
import { useParams } from 'react-router-dom'
import CouponList from './CouponList';
import AddNewCoupon from './AddNewCoupon';
import CouponDetail from './CouponDetail';
import EditCoupon from './EditCoupon';
import PublishCoupon from './PublishCoupon';
import PublishCouponDetails from './PublishCouponDetails';
import EditPublishedCoupan from './EditPublishedCoupan';

export default function MyCoupon() {
    const {tab,page} = useParams();
    
    return (
        <>
        {(page==="list") && <CouponList />}
        {page==="add" && <AddNewCoupon />}
        {(page==="detail" && tab==="coupon-template") && <CouponDetail />}
        {(page==="detail" && tab==="published-coupon") && <PublishCouponDetails />}
        {(page==="update" && tab==="coupon-template") && <EditCoupon />}
        {(page==="update" && tab==="published-coupon") && <EditPublishedCoupan />}
        {page==="publish" && <PublishCoupon />}
        </>
    )
}
