import React, { useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import OTPInput from 'react-otp-input';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { tostE, tostS } from '../../Utils/Toast';
import { cleanAllData, setUserToken } from '../../Actions/ActionCreator';
import { emailVerifyService, resendEmailOtp } from '../../Services/AuthServices';
import { Modal, ModalBody } from 'react-bootstrap';
import checkIcon from '../../Assets/images/check-icon.png';
import logo from './../../Assets/images/logo.png'

export default function EmailVerify() {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user && state.user.data ? state.user.data : null);
    const [loading, setLoading] = useState(false); // State for loader
    const [startTime, setStartTime] = useState(null); // Start time of update process


    const otpValidation = Yup.object().shape({
        otp: Yup.string().required('Please enter otp').min(4, "Enter a valid OTP")
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: ""
        },
        validationSchema: otpValidation,
        onSubmit: async (values) => {
            setStartTime(new Date()); // Record start time
            setLoading(true); // Show loader before making API call
            const payload = {
                email: currentUser?.email,
                emailOtp: values.otp
            }
            emailVerifyService(payload).then((res) => {
                if (res.status === 200) {
                    const respData = res.data.data;
                    if (respData?.merchantDetails && respData?.token) {
                        const userData = res.data.data.merchantDetails;
                        const userToken = res.data.data.token;
                        dispatch(setUserToken(userData, userToken))
                    } else {
                        if (currentUser && currentUser.status === '0') {
                            setTimeout(() => {
                                setLoading(false); // Hide loader after API call completes
                                setStartTime(null); // Reset start time
                                setShow(true);
                                dispatch(cleanAllData());
                            }, 100);
                          
                        } else {
                            setTimeout(() => {
                                setLoading(false); // Hide loader after API call completes
                                setStartTime(null); // Reset start time
                                navigate("/auth/login");
                            }, 100);
                            
                        }
                    }
                }
            }).catch((err) => {
                if (err?.response?.data) {
                    tostE(err?.response?.data?.message);
                }
            }).finally(() => {
                    setLoading(false); // Hide loader after API call completes
                    setStartTime(null); // Reset start time
            });     
        }
    });

    const { errors, values, touched, handleSubmit, setValues } = formik;

    const handleResend = () => {
        setStartTime(new Date()); // Record start time
        setLoading(true);
        const payload = {
            email: currentUser?.email,
        }

        resendEmailOtp(payload).then((res) => {
            if (res.status === 200) {
                setTimeout(() => {
                    setLoading(false); 
                    setStartTime(null); 
                    tostS(res?.data?.message)
                }, 100);
                
            }
        }).catch((err) => {
            if (err?.response?.data) {
                tostE(err?.response?.data?.message);
            }
        }).finally(() => {
            setLoading(false); 
            setStartTime(null); 
        });     
    }

    const handleClose = () => {
        navigate("/auth/login");
    }
    return (
        <>
            {loading && (
                <div className='preload'>
                    <img src={logo} alt="Insta-coupon Logo" className='preloadlogo'/>
                </div>
            )}        
            <div className="form-head mb-4 text-center">
                <h4>Verify your email</h4>
                <p>Enter the OTP sent to your email</p>
            </div>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <div className="form-body">
                        <div className="form-group">
                            <OTPInput
                                renderSeparator={false}
                                containerStyle={"otp-form d-flex mt-3"}
                                inputStyle={{ width: "100%" }}
                                placeholder='----'
                                numInputs={4}
                                value={values.otp}
                                inputType='number'
                                onChange={(otp) => { setValues({ ...values, otp: otp }) }}
                                renderInput={(props) => <input placeholder="-" class={"form-control"} {...props} />}
                            />
                            {errors.otp && touched.otp ? <span className="form-error">{errors.otp}</span> : null}
                        </div>
                        <div className="form-btn-box mt-4">
                            <button type='submit' className="btn btn-secondry w-100" >Verify </button>
                        </div>
                        <div className="recive-otp form-bottom-text text-center mt-3">
                            <span>Didn’t receive OTP? <span onClick={() => { handleResend() }} className="form-link cursor">Resend</span></span>
                        </div>
                    </div>
                </Form>
            </FormikProvider>
            <div className="form-bottom form-bottom-text">
                <Link
                    to={"/auth/login"}
                    className="form-link"
                >
                    Back to Login
                </Link>
            </div>
            <Modal className='modal fade form-modal' show={show} backdrop="static" onHide={handleClose}>
                <ModalBody>
                    <div className="model-head">
                        <figure><img src={checkIcon} alt="check icon" /></figure>
                    </div>
                    <figcaption>
                        <h4>Your Merchant Sign-Up Request has been sent to Admin</h4>
                        <p>Thank you for submitting the request. It’s under review and we will get back to you soon.</p>
                        <button onClick={() => { handleClose() }} className="mt-4 btn btn-secondry min-w">Ok</button>
                    </figcaption>
                </ModalBody>
            </Modal>
        </>
    )
}



