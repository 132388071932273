import { Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from '../Layout/DashboardLayout';
import DashboardScreen from '../Screens/Main/Dashboard';
import MyCoupon from '../Screens/Main/MyCoupons';
import Support from '../Screens/Main/Support';
import BusinessConfig from '../Screens/Main/BusinessConfig.js';
import SettingScreen from '../Screens/Settings/index.js';
import MyAccount from '../Screens/MyAccount/index.js';
import AnalyticsScreen from '../Screens/Main/Analytics/index.js';
import MerchantTermsAndConditions from '../Screens/Common/MerchantTermsAndConditions';
import UserPrivacyPolicy from '../Screens/Common/UserPrivacyPolicy';
import RegistrationAgreement from '../Screens/Common/RegistrationAgreement';
import LogoOnlyLayout from '../Layout/LogoOnlyLayout';

// ----------------------------------------------------------------------

export default function DashBoardRoutes() {
  return useRoutes([
    {
      path: '/merchant',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element:<DashboardScreen />},  
        { path: 'analytics', element:<AnalyticsScreen />},                
        { path: 'my-coupon/:tab/:page/:id?', element:<MyCoupon />},        
        { path: 'support', element:<Support />},        
        { path: 'business-config/:page', element:<BusinessConfig />},        
        { path: 'my-account/:tab/:page?', element:<MyAccount />},        
        { path: 'settings', element:<SettingScreen />},        
        { path: '/merchant', element: <Navigate to="/merchant/dashboard" replace /> },
        { path: '*', element: <Navigate to="/merchant/dashboard" replace /> },
      ]
    },
    {
      path: 'common',
      element:<LogoOnlyLayout />,
      children:[
        { path: 'registration-agreement', element:<RegistrationAgreement />},       
        { path: 'termsandconditions', element:<MerchantTermsAndConditions />},
        { path: 'privacypolicy', element:<UserPrivacyPolicy />},
      ]
    },
    { path: '*', element: <Navigate to="/merchant/dashboard" replace /> }
  ]);
}
